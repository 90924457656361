import { ENDPIONTS, PaginatedResult } from '@api';
import { AddDeductionType, AddOverTimeType } from '@components';
import { useFetch } from '@hooks';
import { DeductionType, OverTimeType } from '@models';
import { Action, ComplexHeader, Table } from '@shared';
import { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const ListOverTimeTypes = () => {
    const MySwal = withReactContent(Swal);

    const [reload, setReload] = useState<boolean | undefined>(undefined);

    const fetchData = useFetch<PaginatedResult<OverTimeType>>({ endPoint: ENDPIONTS.overTimeTypes, reload: reload }, new PaginatedResult<OverTimeType>());

    useEffect(() => {

    }, [fetchData?.isFetching])

    const headers: ComplexHeader[] = [
        { key: "name", title: "Type" },
        { key: "rate", title: "Rate" },
        { key: "createdAt", title: "@", format: "date" },
    ];

    const actions: Action[] = [
        {
            key: '',
            actionType: 'badge',
            click: (d: OverTimeType) => {
                onButtonClick(null, d);
            },
            title: "Edit",
            color: 'dark'
        }
    ];

    const onButtonClick = (e: any, d?: OverTimeType) => {
        e !== null && e.preventDefault();

        MySwal.fire({
            showConfirmButton: false,
            allowOutsideClick: false,
            showCloseButton: true,
            width: 600,
            html: <AddOverTimeType overtimeType={d} callback={() => setReload(prev => prev === undefined ? true : !prev)} />
        })
    }

    return (
        <div className="col-6 d-flex">
            <div className="card profile-box flex-fill">
                <div className="card-body">
                    <h3 className="card-title">
                        OverTime Types
                        <a href="!#" onClick={(e) => onButtonClick(e)} className="edit-icon" data-bs-toggle="modal" data-bs-target="#personal_info_modal">
                            <i className="fa fa-plus" />
                        </a>
                    </h3>
                    <Table
                        actions={actions}
                        class='table table-striped table-hover mb-0'
                        data={fetchData?.data ?? []}
                        headers={headers}
                        isFetchingPage={fetchData.isFetching}
                        showCounter
                        // onPageChange={handlePageChange}
                        paginationClass="row mt-3"
                    />
                </div>
            </div>
        </div>
    )
}

export default ListOverTimeTypes