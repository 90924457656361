import { ENDPIONTS, httpService, PaginatedResult } from "@api";
import { useFetch } from "@hooks";
import { SubBranch } from "@models";
import { Action, ComplexHeader, Table } from "@shared";
import React, { useEffect, useState } from "react";

interface Props {
  branchId: number;
  branchName: string;
}

const ListSubBranches = ({ branchId, branchName }: Props) => {
  const [subBranchName, setSubBranchName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState<boolean | undefined>(undefined);

  const fetchData = useFetch<PaginatedResult<SubBranch>>(
    {
      endPoint: ENDPIONTS.subBranches,
      id: branchId,
      reload,
    },
    new PaginatedResult<SubBranch>()
  );

  useEffect(() => {}, [fetchData?.isFetching]);

  const headers: ComplexHeader[] = [
    { key: "name", title: "name" },
    { key: "createdBy", title: "By" },
    { key: "createdAt", title: "@", format: "date" },
  ];

  const actions: Action[] = [
    {
      key: "",
      actionType: "badge",
      click: () => {},
      title: "Edit",
      color: "warning",
    },
  ];

  const onCreate = async () => {
    if (!subBranchName) return;

    setIsLoading(true);

    const obj = {
      name: subBranchName,
      branchId: branchId,
    };

    const res = await httpService(ENDPIONTS.subBranches).post(obj);
    if (res.status === 201) {
      setSubBranchName("");
      setReload((prev) => (prev === undefined ? true : !prev));
    }
    setIsLoading(false);
  };

  return (
    <>
      <h4>{branchName} sub-branches</h4>
      <br />

      <div className="d-flex justify-content-between align-items-center">
        <div className="col">
          <div className="form-group">
            <label>Name*</label>
            <div className="col-12">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Sub Branch Name"
                onChange={(e) => setSubBranchName(e.target.value)}
              />
            </div>
          </div>
        </div>
        <input
          type="button"
          name="time"
          className="btn btn-primary"
          disabled={isLoading || !subBranchName}
          value={isLoading ? "Please wait..." : "Create su-branch"}
          onClick={onCreate}
        />
      </div>

      <div className="col-12 mt-3">
        <Table
          //   actions={actions}
          class="table table-striped table-hover mb-0"
          data={fetchData?.data ?? []}
          headers={headers}
          isFetchingPage={fetchData.isFetching}
          showCounter
        />
      </div>
    </>
  );
};

export default ListSubBranches;
