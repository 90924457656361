import { ENDPIONTS, PaginatedResult } from "@api";
import { useFetch } from "@hooks";
import { AppraisalEvaluationCriterion } from "@models";
import { ComplexHeader, Table } from "@shared";
import { AppraisalECVM, GoalVM } from "@viewModels";
import React from "react";

interface Props {
  appraisalId: number;
  callback?: () => void;
}

const AppraisalEvaluationCriteria = ({ appraisalId, callback }: Props) => {
  const fetchAppraisalECs = useFetch<AppraisalECVM[]>(
    {
      endPoint: ENDPIONTS.appraisalEvaluationCriteria,
      id: appraisalId,
    },
    []
  );

  const fetchGoals = useFetch<PaginatedResult<GoalVM>>(
    {
      endPoint: ENDPIONTS.appraisalgoals,
      id: appraisalId,
    },
    new PaginatedResult<GoalVM>()
  );

  const headers: ComplexHeader[] = [
    { key: "evaluationCriterion", title: "Criterion" },
    {
      key: "expectedTarget",
      title: "expected Target",
      compute: (row: any) => `${row.expectedTarget}${row.symbol}`,
    },
    {
      key: "actualTarget",
      title: "actual Target",
      compute: (row: any) => `${row.actualTarget}${row.symbol}`,
    },
    {
      key: "weight",
      title: "weight",
      format: "percent",
      total: { format: "percent" },
    },
    { key: "score", title: "score", format: "number" },
    { key: "netScore", title: "net Score", total: { format: "number" } },
    {
      key: "grade",
      title: "grade",
      formatter: (grade: string) => grade?.replace("_", " "),
    },
  ];

  const goalHeaders: ComplexHeader[] = [
    { key: "description", title: "description" },
    { key: "kra", title: "KRA" },
    { key: "status", title: "status" },
    { key: "completion", title: "completion %", format: "percent" },
  ];

  return (
    <>
      <h3>Appraisal Details</h3>
      <hr />
      <div className=" d-flex justify-content-start ">
        <h4>Goals</h4>
        <hr />
      </div>

      <Table
        class="table table-striped table-hover mb-0"
        data={fetchGoals?.data ?? []}
        headers={goalHeaders}
        isFetchingPage={fetchGoals.isFetching}
        showCounter
      />

      <div className="d-flex justify-content-start mt-5">
        <h4>Evaluation Criteria</h4>
        <hr />
      </div>

      <Table
        class="table table-striped table-hover mb-0"
        data={fetchAppraisalECs?.data ?? []}
        headers={headers}
        isFetchingPage={fetchAppraisalECs.isFetching}
        showTotals={true}
        showCounter
      />
    </>
  );
};

export default AppraisalEvaluationCriteria;
