import { ENDPIONTS, Operation, PaginatedResult } from "@api";
import { ApplicantDetails } from "@components";
import { useFetch } from "@hooks";
import { Employee, JobApplication } from "@models";
import {
  Action,
  ComplexHeader,
  ExportData,
  Filterable,
  Table,
  TableVerticalConfigs,
} from "@shared";
import { JobApplicationStatus, JobEducationLevel } from "@viewModels";
import { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const JobOfferApplicants = () => {
  const [queryStrings] = useSearchParams();
  const navigate = useNavigate();
  const jobOfferId = useParams().id;
  const jobTitle = useLocation().state as string;
  const MySwal = withReactContent(Swal);

  const [theTitle, setTheTitle] = useState(jobTitle);
  const [reload, setReload] = useState<boolean | undefined>(undefined);

  const fetchData = useFetch<PaginatedResult<JobApplication>>(
    {
      endPoint: ENDPIONTS.jobOfferApplications,
      queryStrings: queryStrings,
      id: jobOfferId,
      reload: reload,
    },
    new PaginatedResult<JobApplication>()
  );

  useEffect(() => {}, [jobOfferId, fetchData?.isFetching]);

  const headers: ComplexHeader[] = [
    { key: "fullName", title: "full Name" },
    { key: "phone", title: "phone" },
    { key: "email", title: "email" },
    { key: "city", title: "city" },
    { key: "age", title: "age" },
    {
      key: "qualification",
      title: "Qualification",
      formatter: (qualification: string) =>
        qualification.replace("_", " ").capitalize(),
    },
    {
      key: "status",
      title: "status",
      formatter: (status: string) =>
        status.includes("_") ? status.replace("_", " ") : status,
      cellClass: (row: JobApplication) => {
        let res;
        switch (row.status) {
          case JobApplicationStatus.Pending:
            res = "badge bg-secondary";
            break;

          case JobApplicationStatus.Rejected:
            res = "badge bg-danger";
            break;

          case JobApplicationStatus.Short_Listed:
            res = "badge bg-warning";
            break;

          case JobApplicationStatus.Hired:
            res = "badge bg-success";
            break;

          default:
            res = "";
            break;
        }
        return res;
      },
    },
    { key: "createdAt", title: "@", format: "date" },
  ];

  const actions: Action[] = [
    {
      key: "",
      actionType: "badge",
      click: (d: JobApplication) => {
        MySwal.fire({
          showConfirmButton: false,
          allowOutsideClick: false,
          showCloseButton: true,
          width: 1000,
          html: (
            <ApplicantDetails
              applicantId={d.id}
              callback={() =>
                setReload((prev) => (prev === undefined ? true : !prev))
              }
            />
          ),
        });
      },
      title: "Examine",
      color: "primary",
    },
  ];

  const filters: Filterable<JobApplication>[] = [
    {
      key: "city",
      title: "City",
      format: "text",
      operation: Operation.like,
    },
    { key: "age", title: "Age", format: "numberRange" },
    {
      key: "qualification",
      title: "Qualification",
      format: "select",
      data: [
        ...Object.keys(JobEducationLevel)?.map((d) => ({
          id: d,
          name: d.replace("_", " ").capitalize(),
        })),
      ],
    },
    {
      key: "status",
      title: "Application Status",
      format: "select",
      data: [
        ...Object.keys(JobApplicationStatus)?.map((d) => ({
          id: d,
          name: d.replace("_", " ").capitalize(),
        })),
      ],
    },
  ];

  const handlePageChange = (page: number, size: number) => {
    queryStrings.set("size", size.toString());
    queryStrings.set("page", (page - 1).toString());

    navigate({ search: `?${queryStrings.toString()}` });
  };

  const onExport: ExportData = {
    data: () => Promise.resolve(fetchData?.data?.items ?? []),
    fileName: `Job Offer Applicants - ${new Date().toLocaleDateString()}`,
  };

  return (
    <div className="content container-fluid">
      <div className="row align-items-center">
        <div className="col">
          <h3 className="page-title">Job Offer Applicants</h3>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">Jobs</li>
            <li className="breadcrumb-item active">{jobTitle ?? theTitle}</li>
          </ul>
        </div>
        <div className="col-auto float-end ms-auto">
          <TableVerticalConfigs filters={filters} exportAsXSLS={onExport} />
        </div>
      </div>

      <div className="row mt-3">
        <Table
          actions={actions}
          class="table table-striped table-hover mb-0"
          data={fetchData?.data ?? []}
          headers={headers}
          isFetchingPage={fetchData.isFetching}
          showCounter
          onPageChange={handlePageChange}
          paginationClass="row mt-3"
        />
      </div>
    </div>
  );
};

export default JobOfferApplicants;
