import { ENDPIONTS, PaginatedResult } from '@api';
import { ChangeSalary, AddDocument } from '@components';
import { useFetch } from '@hooks';
import { EmpDocument, Employee, SalaryHistory } from '@models';
import { Action, ComplexHeader, Table } from '@shared';
import { FetchEmpDocs } from '@viewModels';
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

interface Props {
    employee: Employee;
}

const ListEmployeeDocs: React.FC<Props> = ({ employee }) => {
    const MySwal = withReactContent(Swal);

    const [reload, setReload] = useState<boolean | undefined>(undefined);

    const fetchEmployeeDocs = useFetch<PaginatedResult<FetchEmpDocs>>({ endPoint: ENDPIONTS.employeeDocs, id: employee.id, reload: reload }, new PaginatedResult<FetchEmpDocs>());

    useEffect(() => {

    }, [fetchEmployeeDocs?.isFetching])

    const onAddClickHandler = (e: any) => {
        e.preventDefault();

        MySwal.fire({
            showConfirmButton: false,
            allowOutsideClick: false,
            showCloseButton: true,
            width: 800,
            html: <AddDocument employee={employee} callback={() => setReload(prev => prev === undefined ? true : !prev)} />
        })
    }

    const headers: ComplexHeader[] = [
        { key: "documentTypeName", title: "Doc. Type" },
        { key: "description", title: "Description" },
        { key: "createdAt", title: "@", format: "date" },
    ];

    const actions: Action[] = [
        {
            key: '',
            actionType: 'badge',
            click: (e: FetchEmpDocs) => {
                window.open(e.path);
            },
            title: "View",
            color: 'dark'
        }
    ];

    return (
        <div className="row">
            <div className="col-8 offset-2 d-flex">
                <div className="card profile-box flex-fill">
                    <div className="card-body">
                        <h3 className="card-title">
                            Documents
                            <a href="!#" onClick={(e) => onAddClickHandler(e)} className="edit-icon" data-bs-toggle="modal" data-bs-target="#personal_info_modal">
                                <i className="fa fa-pencil" />
                            </a>
                        </h3>
                        <Table
                            class='table table-striped table-hover mb-0'
                            data={fetchEmployeeDocs?.data ?? []}
                            headers={headers}
                            isFetchingPage={fetchEmployeeDocs.isFetching}
                            showCounter
                            actions={actions}
                            // onPageChange={handlePageChange}
                            paginationClass="row mt-3"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ListEmployeeDocs