import {
  BASE_CURRENCY,
  ENDPIONTS,
  httpService,
  Operation,
  PaginatedResult,
} from "@api";
import { useFetch } from "@hooks";
import { Branch, Payroll } from "@models";
import {
  Action,
  ComplexHeader,
  Filterable,
  Table,
  TableVerticalConfigs,
} from "@shared";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { FiPlus } from "react-icons/fi";
import { PayrollStatus } from "@viewModels";
import { PayrollDetails } from "@components";

const ListPayroll = () => {
  const [queryStrings] = useSearchParams();
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();

  const [reload, setReload] = useState<boolean | undefined>(undefined);

  const fetchPayrolls = useFetch<PaginatedResult<Payroll>>(
    {
      endPoint: ENDPIONTS.payrolls,
      queryStrings: queryStrings,
      reload: reload,
    },
    new PaginatedResult<Payroll>()
  );

  const fetchBranches = useFetch<PaginatedResult<Branch>>(
    {
      endPoint: ENDPIONTS.branches,
      reload: reload,
      ignorePagination: true,
    },
    new PaginatedResult<Branch>()
  );

  useEffect(() => {}, [fetchPayrolls?.isFetching]);

  const headers: ComplexHeader[] = [
    { key: "branch?.location", title: "branch" },
    { key: "forMonth", title: "for month" },
    { key: "totalEmployees", title: "total Employees" },
    {
      key: "deductions",
      title: "deductions",
      format: "currency",
      currency: () => BASE_CURRENCY,
    },
    {
      key: "allowances",
      title: "allowances",
      format: "currency",
      currency: () => BASE_CURRENCY,
    },
    {
      key: "netSalaries",
      title: "net Salaries",
      format: "currency",
      currency: () => BASE_CURRENCY,
    },
    {
      key: "taxes",
      title: "taxes",
      format: "currency",
      currency: () => BASE_CURRENCY,
    },
    {
      key: "netAmout",
      title: "net Amout",
      format: "currency",
      currency: () => BASE_CURRENCY,
    },
    {
      key: "status",
      title: "status",
      cellClass: (u: Payroll) =>
        u.status === PayrollStatus.Approved
          ? "badge badge-success"
          : "badge badge-danger",
    },
    { key: "date", title: "date", format: "date" },
    // { key: "createdAt", title: "@", format: "date" },
  ];

  const filters: Filterable<Payroll>[] = [
    {
      key: "branchId",
      title: "branch",
      format: "select",
      data: [
        ...fetchBranches?.data?.items?.map((d) => ({
          id: d.id,
          name: d.location,
        })),
      ],
    },
    {
      key: "forMonth",
      title: "Payroll Month",
      format: "month",
      // operation: Operation.like,
    },
  ];

  const actions: Action[] = [
    {
      key: "",
      actionType: "badge",
      click: (d: Payroll) => {
        // MySwal.fire({
        //   showConfirmButton: false,
        //   allowOutsideClick: false,
        //   showCloseButton: true,
        //   width: 1500,
        //   html: <PayrollDetails paryollId={d.id} />,
        // });

        navigate(`/payrolls/details/${d.id}`);
      },
      title: "Details",
      color: "info",
    },
    {
      key: "",
      actionType: "badge",
      click: async (d: Payroll) => {
        Swal.fire({
          title: "Are you sure you want to approve this payroll?",
          text: "By confirming approval, it means that this process is irrevocable, and will be permanently persisted.",
          icon: "warning",
          showCancelButton: true,
          allowEscapeKey: false,
          allowOutsideClick: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, approve it!",
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            const res = await httpService(ENDPIONTS.approvePayroll).update(
              d.id,
              {}
            );
            if (res.status === 204) {
              setReload((prev) => (prev === undefined ? true : !prev));
              Swal.fire("Approved!", "Payroll has been Approved.", "success");
            } else {
              Swal.fire(
                "Oops!",
                "An error occurred, please try again later.",
                "error"
              );
            }
          },
        });
      },
      title: "Approve",
      color: "success",
      disable: (d: Payroll) => d.status === PayrollStatus.Approved,
    },
    {
      key: "",
      actionType: "badge",
      click: async (d: Payroll) => {
        Swal.fire({
          title: "Are you sure you want to roll back this payroll?",
          text: "By confirming roll back, it means that this process is irrevocable, and the payroll will be permanently delted.",
          icon: "warning",
          allowEscapeKey: false,
          allowOutsideClick: false,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, roll back!",
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            const res = await httpService(ENDPIONTS.payrolls).delete(d.id);
            if (res.status === 204) {
              setReload((prev) => (prev === undefined ? true : !prev));
              Swal.fire("Done!", "Payroll has been removed.", "success");
            } else {
              Swal.fire(
                "Oops!",
                "An error occurred, please try again later.",
                "error"
              );
            }
          },
        });
      },
      title: "Roll Back",
      color: "danger",
      disable: (d: Payroll) => d.status === PayrollStatus.Approved,
    },
  ];

  const handlePageChange = (page: number, size: number) => {
    queryStrings.set("size", size.toString());
    queryStrings.set("page", (page - 1).toString());

    navigate({ search: `?${queryStrings.toString()}` });
  };

  return (
    <div className="content container-fluid">
      <div className="row align-items-center">
        <div className="col">
          <h3 className="page-title">Payroll Report</h3>
        </div>
        <div className="col-auto float-end ms-auto">
          <TableVerticalConfigs filters={filters} />
        </div>
      </div>
      <div className="col-12 d-flex mt-5">
        <div className="card profile-box flex-fill">
          <div className="card-body">
            <Table
              actions={actions}
              class="table table-striped table-hover mb-0"
              data={fetchPayrolls.data ?? []}
              headers={headers}
              isFetchingPage={fetchPayrolls.isFetching}
              showCounter
              onPageChange={handlePageChange}
              paginationClass="row mt-3"
              //   rowModifier={rowModifier}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListPayroll;
