import { ENDPIONTS, httpService } from "@api";
import create from "zustand"

interface initialState {
    expectedSalary: number | null
    isSalaryDeductible: boolean
    calculateSalary: (employeeId: number) => void
    calculateIfSalaryIsDeductible: (employeeId: number, newDeduction?: number) => Promise<boolean>
    calculateRemainingLeaves: (employeeId: number, leaveTypeId: number, year: number) => Promise<number | null>
}

export const useEmployeeStore = create<initialState>()((set, get) => ({
    expectedSalary: null,
    isSalaryDeductible: false,
    calculateSalary: async (employeeId: number) => {
        // set(state => ({ expectedSalary: null }))
        const res = await calculateEmployeeSalary(employeeId);
        return set(state => ({ expectedSalary: res }))
    },
    calculateIfSalaryIsDeductible: async (employeeId: number, newDeduction?: number) => {
        const res = await checkIfSalaryIsDeductible(employeeId, newDeduction)
        set(state => ({ isSalaryDeductible: res }));
        return res;
    },
    calculateRemainingLeaves: async (employeeId: number, leaveTypeId: number, year: number) => {
        return await remainingLeaves(employeeId, leaveTypeId, year);
    }
}));

const calculateEmployeeSalary = async (employeeId: number): Promise<number | null> => {
    const res = await httpService(ENDPIONTS.calculateExpectedSalary).getById(employeeId);
    if (res?.status === 200) {
        return (res?.data?.expectedSalary as number) ?? null
    }
    return null;
}

const checkIfSalaryIsDeductible = async (employeeId: number, newDeduction?: number) => {
    const params = new URLSearchParams();
    newDeduction && params.set('newDeduction', newDeduction.toString())

    const res = await httpService(ENDPIONTS.isSalaryDeductible, params).getById(employeeId);
    if (res?.status === 200) {
        return (res?.data?.isDeductible as boolean) ?? false
    }
    return false;
}

const remainingLeaves = async (employeeId: number, leaveTypeId: number, year: number) => {
    let params = new URLSearchParams();
    params.set('empId', employeeId.toString())
    params.set('leaveTypeId', leaveTypeId.toString())
    params.set('year', year.toString())

    const res = await httpService(ENDPIONTS.remainingLeaves, params).getAll();
    if (res?.status === 200) {
        return (res?.data?.remainingDays as number) ?? null
    }
    return null;
}