import {
  BASE_CURRENCY,
  ENDPIONTS,
  httpService,
  Operation,
  PaginatedResult,
} from "@api";
import { PaperHeader } from "@components";
import { useFetch } from "@hooks";
import {
  ComplexHeader,
  ExportData,
  Filterable,
  Table,
  TableVerticalConfigs,
} from "@shared";
import { FetchOverTimesDTO } from "@viewModels";
import { BranchSelection } from "@widgets";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const OverTimesReport = () => {
  const [queryStrings] = useSearchParams();
  const navigate = useNavigate();

  const [data, setData] = useState<PaginatedResult<FetchOverTimesDTO>>(
    new PaginatedResult<FetchOverTimesDTO>()
  );
  const [selectedBranchId, setSelectedBranchId] = useState<number>();
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (selectedBranchId) {
      queryStrings.set("branchId", selectedBranchId?.toString());
      navigate({ search: `?${queryStrings.toString()}` });

      (async function () {
        setIsFetching(true);
        const res = await httpService(
          ENDPIONTS.overTimes,
          queryStrings
        ).getAll();
        if (res.status === 200) {
          setData(res.data);
        }
        setIsFetching(false);
      })();
    } else {
      navigate({ search: `` });
    }
  }, [selectedBranchId, queryStrings]);

  const headers: ComplexHeader[] = [
    { key: "branchName", title: "Name" },
    { key: "empCode", title: "Id" },
    { key: "employeeName", title: "Name" },
    { key: "overTimeTypeName", title: "Overtime Type" },
    { key: "date", title: "Date", format: "date" },
    { key: "hours", title: "Hours" },
    { key: "rate", title: "Rate" },
    {
      key: "amount",
      title: "amount",
      format: "currency",
      currency: () => BASE_CURRENCY,
    },
    // {
    //     key: "status", title: "status",
    //     renderer: { condition: (u: FetchOverTimesDTO) => u.status === AllowanceStatus.Paid, ifTrue: 'badge badge-success', ifFalse: 'badge badge-danger' }
    // },
    { key: "description", title: "description" },
    { key: "createdAt", title: "@", format: "date" },
  ];

  const filters: Filterable<FetchOverTimesDTO>[] = [
    { key: "createdAt", title: "created at", format: "dateRange" },
    {
      key: "employeeName",
      title: "name",
      format: "text",
      operation: Operation.like,
    },
  ];

  const onExport: ExportData = {
    data: () => excelData(),
    fileName: `Over-time Report - ${new Date().toLocaleDateString()}`,
  };

  const excelData = async () => {
    queryStrings.set("size", (0).toString());

    const res = await httpService(ENDPIONTS.overTimes, queryStrings).getAll();
    if (res.status === 200) {
      return (res.data as PaginatedResult<FetchOverTimesDTO>)?.items;
    }

    return [];
  };

  const onPrint = (element: HTMLElement) => {
    var subElement = element.querySelectorAll(":scope > div")[0];
    subElement.classList.remove("d-none");

    var subElement02 = element.querySelectorAll(":scope > div")[1];
    subElement02.classList.add("d-none");

    let table = element.getElementsByTagName("table")[0];
    table.classList.remove("table-striped");
  };

  const handlePageChange = (page: number, size: number) => {
    queryStrings.set("size", size.toString());
    queryStrings.set("page", (page - 1).toString());

    navigate({ search: `?${queryStrings.toString()}` });
  };

  return (
    <div className="content container-fluid">
      <div className="row align-items-center">
        <div className="d-flex justify-content-between mb-1">
          <div className="col-4">
            <h3 className="page-title">Over-time Report</h3>
          </div>
        </div>
      </div>

      <div className="col-10 offset-1 mt-3" id="reportcontents">
        <PaperHeader title="Over-time Report" />
        <div className="row mb-3">
          <div className="col-3">
            <div className="row gap-2">
              <BranchSelection
                className="col"
                returnValue={(val: number) => setSelectedBranchId(val)}
              />
            </div>
          </div>

          <div className="col-auto float-end ms-auto">
            <TableVerticalConfigs
              filters={filters}
              exportAsXSLS={onExport}
              onPrint={{
                ref: "reportcontents",
                prePrint: onPrint,
              }}
            />
          </div>
        </div>
        <Table
          class="table table-striped table-hover mb-0"
          data={data}
          headers={headers}
          isFetchingPage={isFetching}
          showCounter
          onPageChange={handlePageChange}
          paginationClass="row mt-3"
          showTotals
        />
      </div>
    </div>
  );
};

export default OverTimesReport;
