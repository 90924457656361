import { ENDPIONTS, httpService, PagingOptions } from "@api";
import { Employee } from "@models";
import { Action, ComplexHeader, Table } from "@shared";
import { FetchEmployeesVM } from "@viewModels";
import React, { useState } from "react";
import Swal from "sweetalert2";

interface props {
  callback: (e: Employee) => void;
}

const EmployeeLookUp = ({ callback }: props) => {
  const [searchValue, setSearchValue] = useState("");
  const [employees, setEmployees] = useState<FetchEmployeesVM[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const onSearchClickHandler = async () => {
    if (!searchValue) return;
    setIsLoading(true);
    var options = new PagingOptions();
    options.filter<Employee>((f) => f.like("name", searchValue));

    var res = await httpService(ENDPIONTS.employees, options).getAll();
    if (res.status === 200) {
      setEmployees(res?.data?.items ?? []);
    }
    setIsLoading(false);
  };

  const headers: ComplexHeader[] = [
    { key: "employeeId", title: "employee Id" },
    { key: "name", title: "Full Name" },
    { key: "location", title: "Branch" },
    { key: "phone", title: "phone" },
  ];

  const actions: Action[] = [
    {
      key: "",
      actionType: "badge",
      click: (e: Employee) => {
        callback(e);
        Swal.close();
      },
      title: "Select",
      color: "info",
    },
  ];

  return (
    <>
      <h3>Employee Lookup</h3>
      <hr />

      <div className="d-flex justify-content-center gap-3">
        <input
          value={searchValue}
          type="text"
          className="form-control col"
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <input
          type="button"
          className="btn btn-sm btn-info col-2"
          value={isLoading ? "Loading..." : "Search"}
          disabled={isLoading}
          onClick={onSearchClickHandler}
        />
      </div>

      <div className="row mt-5">
        {employees.length > 0 && (
          <Table
            actions={actions}
            class="table table-striped table-hover mb-0"
            data={employees}
            headers={headers}
            showCounter
          />
        )}
      </div>
    </>
  );
};

export default EmployeeLookUp;
