import { ENDPIONTS } from "@api";
import { useFetch } from "@hooks";
import { ComplexHeader, DateHelpers, MyRingLoader, Table } from "@shared";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const TerminationsDashboard = () => {
  const [queryStrings] = useSearchParams();
  const navigate = useNavigate();

  const [form, setForm] = useState<{ from: string; to: string }>({
    from: DateHelpers.dashedDate(queryStrings.get("from") ?? new Date()),
    to: DateHelpers.dashedDate(queryStrings.get("to") ?? new Date()),
  });

  const fetchData = useFetch<{ [key: string]: { [key: string]: any }[] }>(
    {
      endPoint: ENDPIONTS.terminationsDashboard,
      queryStrings: queryStrings,
    },
    {}
  );

  useEffect(() => {
    console.log("from is: ", queryStrings.get("from"));
  }, [fetchData.isFetching]);

  const table1Headers: ComplexHeader[] = [
    { key: "name", title: "Name" },
    { key: "reason", title: "reason" },
    { key: "date", title: "date", format: "date" },
  ];

  const table2Headers: ComplexHeader[] = [
    { key: "reason", title: "reason" },
    { key: "count", title: "count" },
  ];

  const table3Headers: ComplexHeader[] = [
    { key: "branch", title: "branch" },
    { key: "count", title: "count" },
  ];

  const onSubmit = () => {
    if (!form.from || !form.to) return;

    var from = new Date(form.from);
    var to = new Date(form.to);

    if (to < from) return;

    queryStrings.set("from", from.toLocaleDateString());
    queryStrings.set("to", to.toLocaleDateString());

    navigate({ search: `?${queryStrings.toString()}` });
  };

  return (
    <div className="content container-fluid">
      <div className="page-header">
        <div className="d-flex justify-content-between">
          <div className="col-auto">
            <h3 className="page-title">Dashboards</h3>
            <ul className="breadcrumb">
              <li className="breadcrumb-item active">Terminations Dashboard</li>
            </ul>
          </div>
          <div className="col-auto d-flex align-items-center gap-2">
            <input
              type="date"
              className="form-control"
              defaultValue={form.from}
              onChange={(e) => setForm({ ...form, from: e.target.value })}
            />
            <input
              type="date"
              className="form-control"
              defaultValue={form.to}
              onChange={(e) => setForm({ ...form, to: e.target.value })}
            />
            <button className="btn btn-primary" onClick={onSubmit}>
              Apply
            </button>
          </div>
        </div>
      </div>
      {fetchData.isFetching ? (
        <MyRingLoader />
      ) : (
        <>
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-4 text-start">
                  <div className="card">
                    <div className="card-body">
                      <h3 className="card-title">Most Common Termination Reasons</h3>
                      <Table
                        class="table table-striped table-hover mb-0"
                        data={fetchData?.data?.table2 ?? []}
                        headers={table2Headers}
                        isFetchingPage={fetchData.isFetching}
                        showCounter
                        paginationClass="row mt-3"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-8 text-start">
                  <div className="card">
                    <div className="card-body">
                      <h3 className="card-title">Termination Distribution by Branch</h3>
                      <Table
                        class="table table-striped table-hover mb-0"
                        data={fetchData?.data?.table3 ?? []}
                        headers={table3Headers}
                        isFetchingPage={fetchData.isFetching}
                        showCounter
                        paginationClass="row mt-3"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container text-start">
            <div className="card">
              <div className="card-body">
                <h3 className="card-title">Recently Terminated Employees</h3>
                <Table
                  class="table table-striped table-hover mb-0"
                  data={fetchData?.data?.table1 ?? []}
                  headers={table1Headers}
                  isFetchingPage={fetchData.isFetching}
                  showCounter
                  paginationClass="row mt-3"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TerminationsDashboard;
