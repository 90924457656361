import { ENDPIONTS, httpService } from "@api";
import { PaperHeader } from "@components";
import { useFetch } from "@hooks";
import {
  ComplexHeader,
  DateHelpers,
  ExportData,
  Table,
  TableVerticalConfigs,
} from "@shared";
import { Areas, MonthlyAttendanceByBranch } from "@viewModels";
import { useEffect, useState } from "react";
import { RiSearchLine } from "react-icons/ri";

type Search = { branchName: string; date: string };
// const branches = ["HQ Garowe"];

const BranchMonthly = () => {
  const [data, setData] = useState<MonthlyAttendanceByBranch[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useState<Search>({
    branchName: "",
    date: "",
  });

  const [branches, setBranches] = useState<string[]>([]);

  const fetchAttendanceBranches = useFetch<Areas[]>(
    {
      endPoint: ENDPIONTS.DeviceBranches,
    },
    []
  );

  useEffect(() => {
    if (fetchAttendanceBranches?.data?.length > 0) {
      var x = fetchAttendanceBranches?.data?.map((f) => f.area_name ?? "");
      setBranches(x);
    }
  }, [fetchAttendanceBranches.isFetching]);

  const headers: ComplexHeader[] = [
    { key: "employeeName", title: "Name" },
    { key: "startDate", title: "start Date", format: "date" },
    { key: "endDate", title: "end Date", format: "date" },
    { key: "totalHours", title: "total Hours" },
    { key: "avgHoursPerDay", title: "avg Hours Per Day" },
    { key: "noOfPresentDays", title: "Present Days" },
    { key: "noOfAbsentDays", title: "Absent Days" },
  ];

  const onExport: ExportData = {
    data: () => Promise.resolve(data),
    fileName: "Branch Monthly Attendance",
  };

  const onPrint = (element: HTMLElement) => {
    var subElement = element.querySelectorAll(":scope > div")[0];
    subElement.classList.remove("d-none");

    var subElement02 = element.querySelectorAll(":scope > div")[1];
    subElement02.classList.add("d-none");

    let table = element.getElementsByTagName("table")[0];
    table.classList.remove("table-striped");
  };

  const handleSearch = async () => {
    if (!searchParams.branchName) return;

    setIsLoading(true);

    let params = new URLSearchParams();
    searchParams.date && params.set("start_date", searchParams.date);

    const res = await httpService(
      ENDPIONTS.attendanceByBranchMonthly,
      params
    ).getById(searchParams.branchName);
    if (res.status === 200) {
      setData(res.data);
    }
    setIsLoading(false);
  };

  return (
    <div className="col-8 offset-1 mt-3" id="reportcontents">
      <PaperHeader title="Leave Balances Report" />
      <div className="d-flex justify-content-between">
        <div className="col-8">
          <div className="row gap-2 mb-1">
            <div className="col">
              <input
                type="month"
                className="form-control"
                name="passportExpiry"
                onChange={(e) =>
                  setSearchParams((prev) => ({
                    ...prev,
                    date: DateHelpers.toMMddyyyy(e.target.value, true),
                  }))
                }
              />
            </div>
            <div className="col">
              <select
                className="form-control"
                onChange={(e) =>
                  setSearchParams((prev) => ({
                    ...prev,
                    branchName: e.target.value,
                  }))
                }
              >
                <option value=""> </option>
                {branches.map((o, i) => {
                  return (
                    <option key={i} value={o}>
                      {o}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <div className="col-auto float-end ms-auto">
          <button
            disabled={isLoading}
            className="btn btn-sm btn-outline-info rounded-circle m-1 float-right"
            onClick={handleSearch}
          >
            <RiSearchLine />
          </button>
          <TableVerticalConfigs
            exportAsXSLS={onExport}
            onPrint={{
              ref: "reportcontents",
              prePrint: onPrint,
            }}
          />
        </div>
      </div>
      <Table
        class="table table-striped table-hover mb-0"
        data={data}
        headers={headers}
        isFetchingPage={isLoading}
        showCounter
        paginationClass="row mt-3"
      />
    </div>
  );
};

export default BranchMonthly;
