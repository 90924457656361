import { ENDPIONTS, httpService, Operation, PaginatedResult } from "@api";
import { AppraisalEvaluationCriteria } from "@components";
import { useFetch } from "@hooks";
import { Appraisal } from "@models";
import {
  Action,
  ComplexHeader,
  Filterable,
  Table,
  TableVerticalConfigs,
} from "@shared";
import { GetAppraisalsVM } from "@viewModels";
import React, { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const ListAppraisals = () => {
  const cycleId = useParams().id;

  const [queryStrings] = useSearchParams();
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();

  const [reload, setReload] = useState<boolean | undefined>(undefined);

  const fetchAppraisals = useFetch<PaginatedResult<GetAppraisalsVM>>(
    {
      endPoint: ENDPIONTS.cycleAppraisals,
      id: cycleId,
      queryStrings: queryStrings,
      reload: reload,
    },
    new PaginatedResult<GetAppraisalsVM>()
  );

  useEffect(() => {}, [fetchAppraisals?.isFetching]);

  const headers: ComplexHeader[] = [
    { key: "employeeName", title: "Name" },
    { key: "employeeCode", title: "employee Code" },
    { key: "krAsScore", title: "KRA %", format: "percent" },
    { key: "evaluationCriteriaScore", title: "EV %", format: "percent" },
    { key: "overallScore", title: "overall %", format: "percent" },
    { key: "cycleStatus", title: "Cycle Status" },
  ];

  //   const filters: Filterable<GetAppraisalsVM>[] = [
  //     {
  //       key: "employeeName",
  //       title: "Employee name",
  //       format: "text",
  //       operation: Operation.like,
  //     },
  //     {
  //       key: "employeeCode",
  //       title: "Employee Code",
  //       format: "text",
  //       operation: Operation.like,
  //     },
  //   ];

  const actions: Action[] = [
    {
      key: "",
      actionType: "badge",
      click: (d: GetAppraisalsVM) => {
        onBeginEvaluationClickHandler(d.id);
      },
      title: "Begin Evaluation",
      color: "warning",
      hide: (a: GetAppraisalsVM) => a.hasEvaluations,
    },
    {
      key: "",
      actionType: "badge",
      click: (d: GetAppraisalsVM) => {
        onShowEvaluationsClickHandler(d.id);
      },
      title: "Show Evaluations",
      color: "info",
      hide: (a: GetAppraisalsVM) => !a.hasEvaluations,
    },
  ];

  const handlePageChange = (page: number, size: number) => {
    queryStrings.set("size", size.toString());
    queryStrings.set("page", (page - 1).toString());

    navigate({ search: `?${queryStrings.toString()}` });
  };

  const onBeginEvaluationClickHandler = async (appraisalId: number) => {
    const res = await httpService(ENDPIONTS.beginEvaluation).post({
      id: appraisalId,
    });
    if (res.status === 201) {
      setReload((prev) => (prev === undefined ? true : !prev));
      onShowEvaluationsClickHandler(appraisalId);
    }
  };

  const onShowEvaluationsClickHandler = (appraisalId: number) => {
    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 1200,
      html: (
        <AppraisalEvaluationCriteria
          appraisalId={appraisalId}
          callback={() =>
            setReload((prev) => (prev === undefined ? true : !prev))
          }
        />
      ),
    });
  };

  return (
    <div className="content container-fluid">
      <div className="row align-items-center">
        <div className="col">
          <h3 className="page-title">Employee Aprraisals</h3>
        </div>
        <div className="col-auto float-end ms-auto">
          {/* <TableVerticalConfigs filters={filters} /> */}
        </div>
      </div>
      <div className="col-10 offset-1 mt-3">
        <Table
          actions={actions}
          class="table table-striped table-hover mb-0"
          data={fetchAppraisals?.data ?? []}
          headers={headers}
          isFetchingPage={fetchAppraisals.isFetching}
          showCounter
          onPageChange={handlePageChange}
          paginationClass="row mt-3"
        />
      </div>
    </div>
  );
};

export default ListAppraisals;
