import { ENDPIONTS, httpService, PaginatedResult } from "@api";
import { useFetch } from "@hooks";
import { Department, Designation } from "@models";
import { GetAppraisalCyclesVM } from "@viewModels";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

interface Props {
  cycle: GetAppraisalCyclesVM;
  callback?: () => void;
}

const ChangeCycleStatus = ({ cycle, callback }: Props) => {
  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: any, e: any) => {
    setIsLoading(true);

    const obj = {
      id: cycle.id,
      status: data.status,
    };

    const res = await httpService(ENDPIONTS.changeAppraisalCycleStatus).update(
      cycle.id,
      obj
    );

    callback?.();
    setIsLoading(false);
    e.target.reset();
  };

  return (
    <>
      <h4>Change Cycle Status</h4>
      <hr />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="form-group">
            <label>Current Status: {cycle.status.capitalize()}</label>
            <select
              className="form-control select"
              name="status"
              ref={register({ required: true })}
            >
              <option></option>
              <option value="new">
                New (i.e. Employees can submit/edit their goals.)
              </option>
              <option value="ongoing">
                Ongoing (i.e. Employees can update their goals' progress.)
              </option>
              <option value="completed">
                Completed (i.e. The entire cycle is closed.)
              </option>
            </select>
          </div>
        </div>

        <input
          type="submit"
          name="time"
          className="btn btn-primary"
          disabled={isLoading}
          value={isLoading ? "Please wait..." : "Update"}
        />
      </form>
    </>
  );
};

export default ChangeCycleStatus;
