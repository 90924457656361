import { BASE_CURRENCY, ENDPIONTS, PaginatedResult } from "@api";
import { useFetch } from "@hooks";
import { AllowanceDetail, Branch } from "@models";
import { ComplexHeader, Table } from "@shared";
import { useEffect } from "react";

interface Props {
  allowanceId: number;
}

const AllowanceDetails = ({ allowanceId }: Props) => {
  const fetchData = useFetch<PaginatedResult<AllowanceDetail>>(
    { endPoint: ENDPIONTS.allowanceDetails, id: allowanceId },
    new PaginatedResult<AllowanceDetail>()
  );

  useEffect(() => {}, [fetchData?.isFetching, allowanceId]);

  const headers: ComplexHeader[] = [
    {
      key: "amount",
      title: "Amount",
      format: "currency",
      currency: () => BASE_CURRENCY,
    },
    { key: "forMonth", title: "for Month" },
    { key: "status", title: "status" },
    { key: "ref", title: "ref" },
    { key: "createdAt", title: "@", format: "date" },
  ];

  return (
    <>
      <h3>Allowance Details</h3>
      <hr />
      <Table
        class="table table-striped table-hover mb-0"
        data={fetchData?.data ?? []}
        headers={headers}
        isFetchingPage={fetchData.isFetching}
        showCounter
        paginationClass="row mt-3"
      />
    </>
  );
};

export default AllowanceDetails;
