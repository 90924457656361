import { ENDPIONTS } from "@api";
import { useFetch } from "@hooks";
import { MyBarLoader, MyRingLoader } from "@shared";
import React, { useState } from "react";
import { useEffect } from "react";
import { PieChart, Pie, Cell, BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from "recharts";
import DistributionTable from "./DistributionTable";

// Color palette for the charts
const COLORS = ["#00C49F", "#FF6384",  "#FFBB28", "#FF8042",  "#0088FE", "#36A2EB"];

const GeneralDashboard = () => {
  const data = useFetch<{ [key: string]: { [key: string]: any }[] }>(
    {
      endPoint: ENDPIONTS.generalDashboard,
    },
    {}
  );

  useEffect(() => {
  }, [data.isFetching]);

  // Gender Distribution (Pie Chart)
  const genderData = [
    { name: "Male", value: data?.data?.table1?.[0]?.male },
    { name: "Female", value: data?.data?.table1?.[0]?.female },
  ];

  // Active vs. Terminated Employees (Pie Chart)
  const employeeStatusData = [
    { name: "Active", value: data?.data?.table1?.[0]?.active_Employees },
    { name: "Terminated", value: data?.data?.table1?.[0]?.terminated_Employees },
  ];

  // Age Group Distribution (Bar Chart)
  const ageGroupData = data?.data?.table2?.filter((item: any) => item?.ageGroup);

  // Marital Status Distribution (Pie Chart)
  const maritalStatusData = data?.data?.table3?.filter((item: any) => item.maritalStatus);

  // Branch-wise Employee Distribution (Bar Chart)
  const branchData = data?.data?.table5?.map((item: any) => ({
    branch: item.branch,
    count: item.count,
  }));

  // Job Grade Distribution (Bar Chart)
  const jobGradeData = data?.data?.table4?.map((item: any) => ({
    jobGrade: item.jobGrade,
    count: item.count,
  }));

  if (data.isFetching) return <MyRingLoader />;

  return (
    <div className="content container-fluid">
      <div className="page-header">
        <div className="row">
          <div className="col-sm-12">
            <h3 className="page-title">Dashboards</h3>
            <ul className="breadcrumb">
              <li className="breadcrumb-item active">General Dashboard</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4 text-center">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title">Active vs. Terminated Employees</h3>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        data={employeeStatusData}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        outerRadius={100}
                        fill="#8884d8"
                        label
                      >
                        {employeeStatusData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
            <div className="col-md-8 text-center">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title">Job Grade Distribution</h3>
                  <ResponsiveContainer width="100%" height={300}>
                    <BarChart data={jobGradeData}>
                      <XAxis dataKey="jobGrade" />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="count" fill="#FF8042" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4 text-center">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title">Gender Distribution</h3>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        data={genderData}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        outerRadius={100}
                        fill="#8884d8"
                        label
                      >
                        {genderData?.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
            <div className="col-md-8 text-center">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title">Age Group Distribution</h3>
                  <ResponsiveContainer width="100%" height={400}>
                    <BarChart data={ageGroupData} layout="vertical">
                      <XAxis type="number" />
                      <YAxis type="category" dataKey="ageGroup" />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="count" fill="#82ca9d" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4 text-center">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title">Marital Status Distribution</h3>
                  <ResponsiveContainer width="100%" height={300}>
                    <PieChart>
                      <Pie
                        data={maritalStatusData}
                        dataKey="count"
                        nameKey="maritalStatus"
                        cx="50%"
                        cy="50%"
                        outerRadius={100}
                        fill="#82ca9d"
                        label
                      >
                        {maritalStatusData?.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                      </Pie>
                      <Tooltip />
                    </PieChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
            <div className="col-md-8 text-center">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title">Branch-wise Employee Distribution</h3>
                  <ResponsiveContainer width="100%" height={500}>
                    <BarChart
                      data={branchData}
                      layout="vertical"
                      margin={{ top: 20, right: 30, left: 100, bottom: 20 }}
                    >
                      <XAxis type="number" width={50} />
                      <YAxis type="category" dataKey="branch" />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey="count" fill="#8884d8" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title">Branches + Departments + Designations Distribution</h3>
                  {/* Branch + Department + Designation Distribution Table */}
                  <DistributionTable data={data?.data?.table8} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralDashboard;
