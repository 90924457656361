export enum ENDPIONTS {
    permissions = "permissions",
    login = "auth",
    users = "users",
    roles = "roles",
    payrolls = "payrolls",
    payrollGroups = "payrollGroups",
    departments = "departments",
    branches = "branches",
    subBranches = "SubBranches",
    designations = "designations",
    employees = "employees",
    overTimeTypes = "overTimeTypes",
    overTimes = "overTimes",
    leaveTypes = "leaveTypes",
    leaves = "leaves",
    jobOffers = "jobOffers",
    applications = "jobApplications",
    shifts = "shifts",
    encashments = "Encashments",
    kras = "KRAs",
    evaluationCriteria = "EvaluationCriteria",
    appraisalTemplates = "AppraisalTemplates",
    appraisals = "Appraisals",
    appraisalCycles = "AppraisalCycles",
    payrollPreps = "PayrollPreps",
    designationsEmployees = "AppraisalCycles/DesignationsEmployees",
    generateAppraisals = "AppraisalCycles/GenerateAppraisals",
    changeAppraisalCycleStatus = "AppraisalCycles/ChangeAppraisalCycleStatus",
    cycleAppraisals = "Appraisals/CycleAppraisals",
    beginEvaluation = "Appraisals/GenerateAppraisalECs",
    appraisalEvaluationCriteria = "Appraisals/EvaluationCriteria",
    ECUnit = "EvaluationCriteria/ECUnit",
    employeeEncashments = "Encashments/Employee",
    payEncashments = "Encashments/Pay",
    changeRole = "users/changeRole",
    allowedApps = "users/allowedApps",
    changePassword = "users/changePassword",
    attendanceByBranch = "ar/attendance",
    DeviceBranches = "ar/GetDeviceBranches",
    attendanceByBranchMonthly = "ar/monthlyAttendance",
    attendanceByEmployee = "ar/monthlyAttendanceByEmployee",
    employeeAttendanceDetails = "ar/GetEmployeeAttendanceDetails",
    jobOfferApplications = "jobApplications/Joboffer",
    publishedJobOffers = "jobOffers/Published",
    changeSalary = "employees/changesalary",
    lineManager = "employees/LineManager",
    attachUser = "employees/AttachUser",
    uploadEmployees = "employees/Upload",
    employeePersonalInfo = "employees/employeepersonalinfo",
    employeeEmergencyContact = "employees/employeeemergencycontact",
    uploadImage = "employees/uploadImg",
    salaryHistories = "salaryhistories",
    emergencyContacts = "emergencyContacts",
    employeeDocs = "empDocuments",
    documentTypes = "documentTypes",
    allowanceTypes = "allowanceTypes",
    allowances = "allowances",
    allowanceDetails = "AllowanceDetails",
    cancelAllowance = "allowances/Cancel",
    deductions = "deductions",
    deductionDetails = "DeductionDetails",
    deductionTypes = "deductionTypes",
    companyProfiles = "CompanyProfiles",
    companyLogo = "CompanyProfiles/Logo",
    employeeDeductions = "deductions/employee",
    clearDeduction = "deductions/ClearDeduction",
    terminateEmployee = "employees/Terminate",
    undoEmployeeTermination = "employees/UndoTermination",
    employeeLeaves = "leaves/employee",
    remainingLeaves = "leaves/RemainingLeaves",
    generatePayroll = "payrolls/GeneratePayroll",
    approvePayroll = "payrolls/Approve",
    payrollPreview = "payrolls/preview",
    payrollDetails = "payrolls/PayrollDetails",
    checkEmpDeductionFeasibility = "payrolls/CheckEmpDeductionFeasibility",
    calculateExpectedSalary = "employees/CalculateExpectedSalary",
    isSalaryDeductible = "employees/IsSalaryDeductible",
    leaveBalancesReport = "leaves/LeaveBalancesReport",
    annualLeaveReport = "leaves/AnnualLeaveSummary",
    leaveSummaryReport = "leaves/LeaveSummary",
    employeeYearlyAppraisal = "Appraisals/EmployeeYearlyAppraisal",
    yearlyAppraisalTemplateUtilization = 'AppraisalTemplates/YearlyAppraisalTemplateUtilization',
    appraisalgoals = "Goals/Appraisal",
    payrollBranches = "Branches/PayrollBranches",

    terminations = "Terminations",
    employeeTerminations = "Terminations/employee",

    audits = "Audits",

    generalDashboard = "Dashboards/General",
    leavesDashboard = "Dashboards/Leaves",
    terminationsDashboard = "Dashboards/Terminations",

    coa = "test",
}

