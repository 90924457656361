import { ENDPIONTS, httpService } from '@api';
import { DocumentType } from '@models';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';

interface Props {
    callback?: () => void
    documentType?: DocumentType
}

const AddDocumentType: React.FC<Props> = ({ callback, documentType }) => {
    const { register, handleSubmit, errors } = useForm();

    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async (data: any, e: any) => {
        setIsLoading(true);

        let dType: Partial<DocumentType> = {
            name: data.name
        }

        if (documentType?.id) {
            dType.id = documentType.id;
            await httpService(ENDPIONTS.documentTypes).update(documentType.id, dType);
        } else {
            await httpService(ENDPIONTS.documentTypes).post(dType);
        }

        callback?.();
        setIsLoading(false);
        e.target.reset();
    }

    return (
        <>
            <h4>{documentType?.id ? "Edit" : "Add"} Document Type</h4>
            <hr />
            <form onSubmit={handleSubmit(onSubmit)} >
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label>Document Type</label>
                            <div className="col-12">
                                <input type="text" defaultValue={documentType?.name ?? ''} className="form-control" name='name' ref={register({ required: true })} />
                            </div>
                            <span className="text-danger">
                                {errors.name && <span>This field is required</span>}
                            </span>
                        </div>
                    </div>
                </div>

                <input type="submit" name="time" className="btn btn-primary" disabled={isLoading} value={isLoading ? "Please wait..." : "Register"} />
            </form>
        </>
    )
}

export default AddDocumentType