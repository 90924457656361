import { ENDPIONTS, PaginatedResult } from '@api';
import { useFetch } from '@hooks';
import { EmergencyContact } from '@models';
import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AddEmergencyContact from './AddEmergencyContact';

interface Props {
    employeeId: number;
}

const ListEmployeeEmergencyContacts: React.FC<Props> = ({ employeeId }) => {
    const MySwal = withReactContent(Swal);

    const [reload, setReload] = useState<boolean | undefined>(undefined);

    const fetcheContacts = useFetch<PaginatedResult<EmergencyContact>>({ endPoint: ENDPIONTS.emergencyContacts, id: employeeId, reload: reload }, new PaginatedResult<EmergencyContact>());

    useEffect(() => {

    }, [employeeId, fetcheContacts.isFetching])


    const onAddUserClickHandler = (e: any, eContact?: EmergencyContact) => {
        e.preventDefault();

        MySwal.fire({
            showConfirmButton: false,
            allowOutsideClick: false,
            showCloseButton: true,
            width: 600,
            html: <AddEmergencyContact eContact={eContact} employeeId={employeeId} callback={() => setReload(prev => prev === undefined ? true : !prev)} />
        })
    }


    return (
        <div className="col-md-6 d-flex">
            <div className="card profile-box flex-fill">
                <div className="card-body">
                    <h3 className="card-title">Emergency Contact
                        <a href="!#" onClick={(e) => onAddUserClickHandler(e)} className="edit-icon" data-bs-toggle="modal" data-bs-target="#emergency_contact_modal">
                            <i className="fa fa-pencil" />
                        </a>
                    </h3>
                    {
                        fetcheContacts?.data?.items?.map((c, idx) =>
                        (
                            <>
                                <a href='!#' onClick={(e) => onAddUserClickHandler(e, c)}>
                                    <h5 className="section-title">Edit</h5>
                                </a>
                                <ul className="personal-info">
                                    <li>
                                        <div className="title">Name</div>
                                        <div className="text">{c.name}</div>
                                    </li>
                                    <li>
                                        <div className="title">Relationship</div>
                                        <div className="text">{c.relationship}</div>
                                    </li>
                                    <li>
                                        <div className="title">Phone </div>
                                        <div className="text">{c.contact}</div>
                                    </li>
                                </ul>
                                <hr />
                            </>
                        )
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default ListEmployeeEmergencyContacts