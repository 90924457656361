import { ENDPIONTS, httpService } from '@api';
import { LeaveType } from '@models';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';

interface Props {
    callback?: () => void
    leaveType?: LeaveType
}

const AddLeaveType: React.FC<Props> = ({ callback, leaveType }) => {
    const { register, handleSubmit, errors } = useForm();

    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async (data: any, e: any) => {
        setIsLoading(true);

        let dType: Partial<LeaveType> = {
            name: data.name,
            numberOfDays: data.numberOfDays
        }

        if (leaveType?.id) {
            dType.id = leaveType.id;
            await httpService(ENDPIONTS.leaveTypes).update(leaveType.id, dType);
        } else {
            await httpService(ENDPIONTS.leaveTypes).post(dType);
        }

        callback?.();
        setIsLoading(false);
        e.target.reset();
    }

    return (
        <>
            <h4>{leaveType?.id ? "Edit" : "Add"} Leave Type</h4>
            <hr />
            <form onSubmit={handleSubmit(onSubmit)} >
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label>Leave Type</label>
                            <div className="col-12">
                                <input type="text" defaultValue={leaveType?.name ?? ''} className="form-control" name='name' ref={register({ required: true })} />
                            </div>
                            <span className="text-danger">
                                {errors.name && <span>This field is required</span>}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label>number Of Days</label>
                            <div className="col-12">
                                <input type="number" defaultValue={leaveType?.numberOfDays ?? ''} className="form-control" name='numberOfDays' ref={register({ required: true })} />
                            </div>
                            <span className="text-danger">
                                {errors.numberOfDays && <span>This field is required</span>}
                            </span>
                        </div>
                    </div>
                </div>
                <input type="submit" name="time" className="btn btn-primary" disabled={isLoading} value={isLoading ? "Please wait..." : "Register"} />
            </form>
        </>
    )
}

export default AddLeaveType