import { ENDPIONTS, httpService } from "@api";
import { Employee } from "@models";
import { DateHelpers } from "@shared";
import { useEmployeeStore } from "@stores";
import { EmployeePersonalInfo } from "@viewModels";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

interface Props {
  callback?: () => void;
  employee?: Employee;
}

const ChangeSalary: React.FC<Props> = ({ callback, employee }) => {
  const calculateSalary = useEmployeeStore((s) => s.calculateSalary);
  const calculateIfSalaryIsDeductible = useEmployeeStore(
    (s) => s.calculateIfSalaryIsDeductible
  );

  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: any, e: any) => {
    if (!employee?.id) return;

    setIsLoading(true);

    let emp = {
      id: employee?.id,
      amount: data.amount,
      description: data.description,
    };

    await httpService(ENDPIONTS.changeSalary).update(employee.id, emp);

    calculateSalary(employee?.id);
    await calculateIfSalaryIsDeductible(employee?.id);

    callback?.();
    setIsLoading(false);
    e.target.reset();
  };

  return (
    <>
      <h4>Change Employee Salary</h4>
      <hr />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Amount</label>
              <div className="col-12">
                <input
                  type="number"
                  defaultValue={employee?.salary ?? ""}
                  className="form-control"
                  name="amount"
                  ref={register({ required: true })}
                />
              </div>
              <span className="text-danger">
                {errors.amount && <span>This field is required</span>}
              </span>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <div className="form-group">
              <label htmlFor="description">Remarks</label>
              <textarea
                className="form-control mb-4"
                name="description"
                id="description"
                aria-label="With textarea"
                ref={register({ required: true })}
              ></textarea>
              <span className="text-danger">
                {errors.description && <span>This field is required</span>}
              </span>
            </div>
          </div>
        </div>

        <input
          type="submit"
          name="time"
          className="btn btn-primary"
          disabled={isLoading}
          value={isLoading ? "Please wait..." : "Register"}
        />
      </form>
    </>
  );
};

export default ChangeSalary;
