import { ENDPIONTS } from "@api";
import { useFetch } from "@hooks";
import { AppraisalTemplate } from "@models";
import { ComplexHeader, Table } from "@shared";
import React from "react";

interface Props {
  templateId: number;
}

const TemplateDetails = ({ templateId }: Props) => {
  const template = useFetch<AppraisalTemplate>(
    {
      endPoint: ENDPIONTS.appraisalTemplates,
      id: templateId,
    },
    new AppraisalTemplate()
  );

  const designations: ComplexHeader[] = [
    { key: "department?.name", title: "Department" },
    { key: "designation?.name", title: "Designation" },
  ];

  const kras: ComplexHeader[] = [{ key: "kra?.name", title: "KRA" }];

  const ecs: ComplexHeader[] = [
    { key: "evaluationCriterion?.name", title: "criterion" },
    { key: "expectedTarget", title: "expected Target" },
    { key: "weight", title: "weight", format: "percent" },
  ];

  return (
    <>
      <h3>Template Details</h3>
      <hr />

      <div className="d-flex justify-content-start mt-5">
        <h3>Template Info:</h3>
      </div>

      <div className="d-flex justify-content-between">
        <span className="h6">Template Name: {template?.data?.name}</span>
        <span className="h6">Cycle Type: {template?.data?.cycleType}</span>
      </div>
      <div className="d-flex justify-content-between">
        <span className="h6">
          Key Result Area: %{template?.data?.kRAsTotalPercentage}
        </span>
        <span className="h6 ">
          Evaluation Criteria : %
          {template?.data?.evaluationCriteriaTotalPercentage}
        </span>
      </div>
      <div className="d-flex justify-content-start">
        <span>Remarks: {template?.data?.remarks} </span>
      </div>
      <div className="d-flex">
        <div className="col-6 offset-3">
          <hr />
        </div>
      </div>

      <div className="d-flex justify-content-start mt-3">
        <h3>Departments & Designations:</h3>
      </div>

      <div className="col-8 offset-2">
        <Table
          class="table table-striped table-hover mb-0"
          data={template?.data.appraisalTemplateDesignations ?? []}
          headers={designations}
          isFetchingPage={template.isFetching}
          showCounter
        />
      </div>

      {template?.data?.kRAsTotalPercentage > 0 && (
        <>
          <div className="d-flex justify-content-start mt-3">
            <h3>Key Result Areas:</h3>
          </div>
          <div className="col-8 offset-2">
            <Table
              class="table table-striped table-hover mb-0"
              data={template?.data.appraisalTemplateKRAs ?? []}
              headers={kras}
              isFetchingPage={template.isFetching}
              showCounter
            />
          </div>
        </>
      )}

      {template?.data?.evaluationCriteriaTotalPercentage > 0 && (
        <>
          <div className="d-flex justify-content-start mt-3">
            <h3>Evaluation Criteria:</h3>
          </div>
          <div className="col-8 offset-2">
            <Table
              class="table table-striped table-hover mb-0"
              data={template?.data.appraisalTemplateEvaluationCriteria ?? []}
              headers={ecs}
              isFetchingPage={template.isFetching}
              showCounter
            />
          </div>
        </>
      )}
    </>
  );
};

export default TemplateDetails;
