import { ENDPIONTS, httpService } from "@api";
import { useFetch } from "@hooks";
import { ComplexHeader, Table } from "@shared";
import { EmployeeAttendanceDetailsVM } from "@viewModels";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

interface Props {
  employeeCode: string;
  startDate: string;
  endDate: string;
}

const EmployeeAttendanceDetails = ({
  employeeCode,
  startDate,
  endDate,
}: Props) => {
  // const [queryStrings] = useSearchParams();
  // queryStrings.set("start_date", startDate);
  // queryStrings.set("end_date", endDate);

  // const fetchData = useFetch<EmployeeAttendanceDetailsVM[]>(
  //   {
  //     endPoint: ENDPIONTS.employeeAttendanceDetails,
  //     id: employeeCode,
  //     queryStrings,
  //   },
  //   []
  // );

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async function () {
      setIsLoading(true);

      const params = new URLSearchParams();
      params.set("start_date", startDate);
      params.set("end_date", endDate);

      const res = await httpService(
        ENDPIONTS.employeeAttendanceDetails,
        params
      ).getById(employeeCode);
      if (res.status === 200) {
        setData(res?.data);
      }

      setIsLoading(false);
    })();
  }, [employeeCode, startDate, endDate]);

  const headers: ComplexHeader[] = [
    { key: "employeeName", title: "Name" },
    { key: "date", title: "@", format: "date" },
    { key: "timeIn", title: "In" },
    { key: "timeOut", title: "Out" },
    { key: "totalHours", title: "Total Hours" },
    { key: "status", title: "Status" },
  ];

  return (
    <>
      <h3>Employee Attendance Details</h3>
      <Table
        class="table table-striped table-hover mb-0"
        data={data ?? []}
        headers={headers}
        isFetchingPage={isLoading}
        showCounter
        // onPageChange={handlePageChange}
        paginationClass="row mt-3"
        showTotals
      />
    </>
  );
};

export default EmployeeAttendanceDetails;
