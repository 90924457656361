import { ENDPIONTS, httpService } from "@api";
import { AddEvaluationCriteriaUnit } from "@components";
import { useFetch } from "@hooks";
import { EvaluationCriteriaUnit, EvaluationCriterion } from "@models";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

interface Props {
  selectedEvaluationCriterion?: EvaluationCriterion;
  callback?: () => void;
}
const AddEvaluationCriteria = ({
  selectedEvaluationCriterion,
  callback,
}: Props) => {
  const MySwal = withReactContent(Swal);

  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const fetchECUnit = useFetch<EvaluationCriteriaUnit[]>(
    {
      endPoint: ENDPIONTS.ECUnit,
    },
    []
  );

  useEffect(() => {}, [fetchECUnit?.isFetching]);

  const onSubmit = async (data: any, e: any) => {
    setIsLoading(true);

    const evaluationCriterion: Partial<EvaluationCriterion> = {
      name: data.name,
      unit: data.unit,
      symbol: fetchECUnit?.data?.find((u) => u.unit === data.unit)?.symbol,
    };

    if (selectedEvaluationCriterion?.id) {
      evaluationCriterion.id = selectedEvaluationCriterion.id;
      const res = await httpService(ENDPIONTS.evaluationCriteria).update(
        selectedEvaluationCriterion.id,
        evaluationCriterion
      );
    } else {
      const res = await httpService(ENDPIONTS.evaluationCriteria).post(
        evaluationCriterion
      );
      if (res.status === 201) {
        Swal.fire({
          icon: "success",
          text: "New EvaluationCriterion has been successfully registered.",
          showConfirmButton: false,
        });
      }
    }

    callback?.();
    setIsLoading(false);
    e.target.reset();
  };

  const addUnitClickHandler = (e: any) => {
    e.preventDefault();

    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 600,
      html: <AddEvaluationCriteriaUnit />,
    });
  };

  return (
    <>
      <h4>Add Evaluation Criterion</h4>
      <hr />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label> Evaluation Criterion</label>
              <div className="col-12">
                <input
                  defaultValue={selectedEvaluationCriterion?.name}
                  type="text"
                  className="form-control"
                  placeholder="Enter payroll group name"
                  name="name"
                  ref={register({ required: true })}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Select Unit</label>{" "}
              <a
                href="#/"
                className="h6 text-decoration-underline mx-3"
                onClick={(e) => addUnitClickHandler(e)}
              >
                + add unit
              </a>
              <div className="col-12">
                <select
                  className="form-control select"
                  name="unit"
                  ref={register({ required: true })}
                >
                  <option></option>
                  {fetchECUnit?.data?.map((u, i) => (
                    <option key={i} value={u.unit}>
                      {u.unit} - {u.symbol}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        <input
          type="submit"
          name="time"
          className="btn btn-primary"
          disabled={isLoading}
          value={
            isLoading
              ? "Please wait..."
              : selectedEvaluationCriterion?.id
              ? "Update"
              : "Register"
          }
        />
      </form>
    </>
  );
};

export default AddEvaluationCriteria;
