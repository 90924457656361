import React, { useState } from "react";
import { AiFillFolder, AiFillFolderOpen } from "react-icons/ai";
import { MdOutlineSubdirectoryArrowRight } from "react-icons/md";

interface Props {
  data: { [key: string]: any }[];
}

const DistributionTable = ({ data }: Props) => {
  // State to keep track of expanded branches and departments
  const [expandedBranches, setExpandedBranches] = useState<string[]>([]);
  const [expandedDepartments, setExpandedDepartments] = useState<string[]>([]);

  const groupedData = data?.reduce((acc, curr) => {
    const { branch, department, designation, count } = curr;

    if (!acc[branch]) {
      acc[branch] = {};
    }
    if (!acc[branch][department]) {
      acc[branch][department] = [];
    }
    acc[branch][department].push({ designation, count });

    return acc;
  }, {} as Record<string, Record<string, Array<{ designation: string; count: number }>>>);

  // Toggle branch expansion
  const toggleBranch = (branch: string) => {
    setExpandedBranches(expandedBranches.includes(branch) ? expandedBranches.filter((b) => b !== branch) : [branch]);
  };

  // Toggle department expansion
  const toggleDepartment = (branch: string, department: string) => {
    const deptId = `${branch}-${department}`;
    setExpandedDepartments(
      expandedDepartments.includes(deptId) ? expandedDepartments.filter((d) => d !== deptId) : [deptId]
    );
  };

  return (
    <>
      <table className="table table-sm table-bordered" style={{ textAlign: "start" }}>
        <thead>
          <tr>
            <th>Branch</th>
            <th>Total Count</th>
          </tr>
        </thead>
        <tbody>
          {!groupedData ? (
            <p>no data</p>
          ) : (
            <>
              {Object.keys(groupedData)?.map((branch) => {
                // Calculate the total count for the branch
                const totalBranchCount = Object.keys(groupedData[branch]).reduce(
                  (branchAcc, dept) =>
                    branchAcc + groupedData[branch][dept].reduce((deptAcc: any, item: any) => deptAcc + item.count, 0),
                  0
                );

                return (
                  <React.Fragment key={branch}>
                    {/* First level: Branch row */}
                    <tr onClick={() => toggleBranch(branch)} style={{ cursor: "pointer" }}>
                      <td>
                        {expandedBranches.includes(branch) ? <AiFillFolderOpen /> : <AiFillFolder />} {branch}
                      </td>
                      <td>{totalBranchCount}</td>
                    </tr>

                    {/* Expandable Departments */}
                    {expandedBranches.includes(branch) &&
                      Object.keys(groupedData[branch]).map((department) => {
                        // Calculate the total count for the department
                        const totalDeptCount = groupedData[branch][department].reduce(
                          (deptAcc: any, item: any) => deptAcc + item.count,
                          0
                        );
                        const deptId = `${branch}-${department}`;

                        return (
                          <React.Fragment key={deptId}>
                            {/* Second level: Department row */}
                            <tr
                              onClick={() => toggleDepartment(branch, department)}
                              style={{ cursor: "pointer", paddingLeft: "20px", backgroundColor: "#f1f1f1" }}
                            >
                              <td style={{ paddingLeft: "30px" }}>
                                <MdOutlineSubdirectoryArrowRight />{" "}
                                {expandedDepartments.includes(`${branch}-${department}`) ? <AiFillFolderOpen /> : <AiFillFolder />}{" "}
                                {department}
                              </td>
                              <td>{totalDeptCount}</td>
                            </tr>

                            {/* Expandable Designations */}
                            {expandedDepartments.includes(deptId) && (
                              <tr>
                                <td colSpan={2}>
                                  <div className="container" style={{width:'50%'}}>
                                    <table className="table table-sm table-bordered">
                                      <thead>
                                        <tr className="fw-bolder">
                                          <td>Designation</td>
                                          <td>Count</td>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {groupedData[branch][department].map((item: any, index: any) => (
                                          <tr key={index}>
                                            <td>{item.designation}</td>
                                            <td>{item.count}</td>
                                          </tr>
                                        ))}
                                      </tbody>
                                    </table>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        );
                      })}
                  </React.Fragment>
                );
              })}
            </>
          )}
        </tbody>
      </table>
    </>
  );
};

export default DistributionTable;
