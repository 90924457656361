import { ENDPIONTS, httpService } from "@api";
import { PayrollPreviewVM } from "@viewModels";
import create from "zustand"

interface initialState {
    isLocalStorageCleared: boolean,
    clearStorage: () => void,
}

export const usePayrollStore = create<initialState>()((set, get) => ({
    isLocalStorageCleared: true,
    clearStorage: () => {
        localStorage.removeItem("payrollList");
        localStorage.removeItem("employeeDeductions");
    }
}));