import { ENDPIONTS, Operation, PaginatedResult } from "@api";
import { CustomeLogo } from "@components";
import { useFetch } from "@hooks";
import { JobOffer } from "@models";
import {
  Action,
  ComplexHeader,
  DateHelpers,
  Filterable,
  Table,
  TableVerticalConfigs,
} from "@shared";
import { useEffect, useLayoutEffect, useState } from "react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";

const FindJob = () => {
  const [queryStrings] = useSearchParams();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    queryStrings.delete("fbclid");
    navigate({ search: `` });
  }, []);

  const fetchData = useFetch<PaginatedResult<JobOffer>>(
    { endPoint: ENDPIONTS.publishedJobOffers, queryStrings: queryStrings },
    new PaginatedResult<JobOffer>()
  );

  const [screenSize, setScreenSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // Event handler function for resizing
  const handleResize = () => {
    setScreenSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    // Attach event listener on component mount
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {}, [fetchData?.isFetching]);

  const headers: ComplexHeader[] = [
    { key: "title", title: "Title" },
    { key: "location", title: "location" },
    { key: "type", title: "type", formatter: (type) => type.replace("_", " ") },
    {
      key: "levelOfEducation",
      title: "level Of Education",
      formatter: (levelOfEducation) => levelOfEducation.replace("_", " "),
    },
    { key: "createdAt", title: "Posted At", format: "date" },
    { key: "expiryDate", title: "expiry Date", format: "date" },
  ];

  const actions: Action[] = [
    {
      key: "1",
      actionType: "badge",
      click: (d: JobOffer) => {
        navigate(`/recruitments/${d.id}`, { state: d });
      },
      title: "View",
      color: "dark",
    },
  ];

  const filters: Filterable<JobOffer>[] = [
    { key: "title", title: "title", format: "text", operation: Operation.like },
  ];

  const handlePageChange = (page: number, size: number) => {
    queryStrings.set("size", size.toString());
    queryStrings.set("page", (page - 1).toString());

    navigate({ search: `?${queryStrings.toString()}` });
  };

  return (
    <div
      className="main-wrapper"
      style={{
        background:
          " linear-gradient(150deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)",
        height: "100vh",
      }}
    >
      <div className="row">
        <div className="d-flex justify-content-center mt-5">
          <NavLink to={"/recruitments"}>
            <CustomeLogo />
          </NavLink>
        </div>
        {screenSize?.width > 720 && (
          <div className="content container-fluid mt-5">
            <div className="container d-flex justify-content-end">
              <TableVerticalConfigs
                filters={filters}
                className="btn-dark"
                color="white"
              />
            </div>
            <div className="container mt-3">
              <Table
                actions={actions}
                class="table table-striped table-hover mb-0"
                data={fetchData?.data ?? []}
                headers={headers}
                isFetchingPage={fetchData.isFetching}
                showCounter
                onPageChange={handlePageChange}
                paginationClass="row mt-3"
              />
            </div>
          </div>
        )}

        {screenSize.width <= 720 && (
          <div className="container mt-5">
            {fetchData?.data?.items?.map((j, idx) => {
              return (
                <div className="card job-card m-1">
                  <div className="card-body">
                    <h2 className="card-title">{j?.title}</h2>
                    <p className="card-text">
                      Location: {j?.location} - {j?.type?.replace("_", " ")}
                    </p>
                    <p className="card-text">
                      Education Level: {j?.levelOfEducation?.replace("_", " ")}
                    </p>
                    <p className="card-text">
                      Expiry Date:
                      {DateHelpers.forwardSlashedDate(j?.expiryDate)}
                    </p>
                    <p className="card-text d-flex justify-content-end">
                      <span
                        className="btn btn-sm btn-primary"
                        onClick={() =>
                          navigate(`/recruitments/${j.id}`, { state: j })
                        }
                      >
                        Apply
                      </span>
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default FindJob;
