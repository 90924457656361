import { ENDPIONTS, httpService } from "@api";
import { AllowanceType } from "@models";
import { TypeOfAllowance } from "@viewModels";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

interface Props {
  callback?: () => void;
  allowanceType?: AllowanceType;
}

const AddAllowanceType: React.FC<Props> = ({ callback, allowanceType }) => {
  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: any, e: any) => {
    setIsLoading(true);

    let aType: Partial<AllowanceType> = {
      name: data.name,
      type: data.type,
    };

    if (allowanceType?.id) {
      aType.id = allowanceType.id;
      await httpService(ENDPIONTS.allowanceTypes).update(
        allowanceType.id,
        aType
      );
    } else {
      await httpService(ENDPIONTS.allowanceTypes).post(aType);
    }

    callback?.();
    setIsLoading(false);
    e.target.reset();
  };

  return (
    <>
      <h4>{allowanceType?.id ? "Edit" : "Add"} Allowance Type</h4>
      <hr />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Allowance Type</label>
              <div className="col-12">
                <input
                  type="text"
                  defaultValue={allowanceType?.name ?? ""}
                  className="form-control"
                  name="name"
                  ref={register({ required: true })}
                />
              </div>
              <span className="text-danger">
                {errors.name && <span>This field is required</span>}
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Type</label>
              <select
                className="form-control select"
                name="type"
                ref={register({ required: true })}
              >
                <option></option>
                {Object.keys(TypeOfAllowance).map((r: string, i) => {
                  return (
                    <option
                      selected={
                        allowanceType?.type.toLowerCase() === r.toLowerCase()
                      }
                      key={i}
                      value={r}
                    >
                      {r}
                    </option>
                  );
                })}
              </select>
              <span className="text-danger">
                {errors.type && <span>This field is required</span>}
              </span>
            </div>
          </div>
        </div>
        <input
          type="submit"
          name="time"
          className="btn btn-primary"
          disabled={isLoading}
          value={
            isLoading
              ? "Please wait..."
              : allowanceType?.id
              ? "Update"
              : "Register"
          }
        />
      </form>
    </>
  );
};

export default AddAllowanceType;
