import { ENDPIONTS, httpService, PaginatedResult } from "@api";
import { useFetch } from "@hooks";
import { FetchEmployeesVM } from "@viewModels";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

interface Props {
  callback?: () => void;
  employeeId?: number;
  lineManagerId: number;
}

const LineManager = ({ employeeId, callback, lineManagerId }: Props) => {
  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const fetchEmployees = useFetch<PaginatedResult<FetchEmployeesVM>>(
    {
      endPoint: ENDPIONTS.employees,
    },
    new PaginatedResult<FetchEmployeesVM>()
  );

  const onSubmit = async (data: any, e: any) => {
    if (!employeeId) return;

    setIsLoading(true);

    let obj = {
      id: employeeId,
      lineManagerId: +data.lineManagerId,
    };

    await httpService(ENDPIONTS.lineManager).update(employeeId, obj);

    callback?.();
    setIsLoading(false);
    e.target.reset();
  };

  return (
    <>
      <h4>Set Employee Line Manager</h4>
      <hr />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="form-group">
            <label>Select Manager</label>
            <select
              className="form-control select"
              name="lineManagerId"
              ref={register()}
            >
              <option></option>
              {fetchEmployees?.data?.items?.map((e, idx) => {
                return (
                  <option
                    selected={e?.id === lineManagerId}
                    key={idx}
                    value={e.id}
                  >
                    {e.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>

        <input
          type="submit"
          name="time"
          className="btn btn-primary"
          disabled={isLoading}
          value={isLoading ? "Please wait..." : "Confirm"}
        />
      </form>
    </>
  );
};

export default LineManager;
