import { ENDPIONTS, httpService, PaginatedResult, PagingOptions } from "@api";
import { useFetch } from "@hooks";
import { Encashment, LeaveType } from "@models";
import { useEmployeeStore } from "@stores";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

const options = new PagingOptions();
options.filter<LeaveType>((f) => f.eq("isEncashable", true));

interface Props {
  employeeId: number;
  encashment?: Encashment;
  callback?: () => void;
}

const AddEncashment = ({ callback, encashment, employeeId }: Props) => {
  const calculateRemainingLeaves = useEmployeeStore(
    (s) => s.calculateRemainingLeaves
  );

  const { register, handleSubmit, errors } = useForm();

  const [selectedLeaveTypeId, setSelectedLeaveTypeId] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [reamaingDays, setReamaingDays] = useState<number | null>(null);

  const fetchLeaveTypes = useFetch<PaginatedResult<LeaveType>>(
    { endPoint: ENDPIONTS.leaveTypes, queryStrings: options },
    new PaginatedResult<LeaveType>()
  );

  useEffect(() => {}, [fetchLeaveTypes.isFetching]);

  useEffect(() => {
    if (selectedLeaveTypeId > 0) {
      (async function () {
        var res = await calculateRemainingLeaves(
          employeeId,
          selectedLeaveTypeId,
          new Date().getFullYear()
        );
        setReamaingDays(res);
        const leaveType = fetchLeaveTypes.data.items.filter(
          (f) => f.id === selectedLeaveTypeId
        )[0];

        (document.getElementById("numberOfDays") as HTMLInputElement).value =
          leaveType.numberOfDays.toString();
        (document.getElementById("remainedDays") as HTMLInputElement).value =
          res?.toString() ?? "N/A";
        if (leaveType.isAnnualLeave) {
          (
            document.getElementById("allowedDaysDisplay") as HTMLInputElement
          ).style.display = "none";
        } else {
          (
            document.getElementById("allowedDaysDisplay") as HTMLInputElement
          ).style.display = "block";
        }
      })();
    }
  }, [selectedLeaveTypeId, employeeId]);

  const onSubmit = async (data: any, e: any) => {
    if (!employeeId) return;
    if (!reamaingDays) return alert("Remaining days are 0");

    if (+data.noOfDays > reamaingDays)
      return alert(
        `remaining days ${reamaingDays} can not be less than the number of requested days ${data.noOfDays}.`
      );

    setIsLoading(true);

    let dType: Partial<Encashment> = {
      employeeId: employeeId,
      leaveTypeId: data.leaveTypeId,
      noOfDays: data.noOfDays,
      rate: data.rate,
    };

    if (encashment?.id) {
      //   dType.id = encashment.id;
      //   await httpService(ENDPIONTS.encashments).update(encashment.id, dType);
    } else {
      await httpService(ENDPIONTS.encashments).post(dType);
    }

    callback?.();
    e.target.reset();

    setIsLoading(false);
  };

  return (
    <>
      <h4>{encashment?.id ? "Edit" : "Add"} Encashment</h4>
      <hr />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="form-group">
            <label>leave Type</label>
            <select
              className="form-control select"
              name="leaveTypeId"
              onChange={(e) => setSelectedLeaveTypeId(+e.target.value)}
              ref={register({ required: true })}
            >
              <option></option>
              {fetchLeaveTypes?.data?.items?.map((r: LeaveType, i: any) => {
                return (
                  <option
                    key={i}
                    value={r.id}
                    selected={r.id === encashment?.leaveTypeId}
                  >
                    {r.name}
                  </option>
                );
              })}
            </select>
            <span className="text-danger">
              {errors.leaveTypeId && <span>This field is required</span>}
            </span>
          </div>
        </div>
        {selectedLeaveTypeId > 0 && (
          <>
            <div className="row">
              <div className="col" id="allowedDaysDisplay">
                <div className="form-group">
                  <label>Number of allowed days</label>
                  <div className="col-12">
                    <input
                      type="number"
                      id="numberOfDays"
                      disabled
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label>Remained days</label>
                  <div className="col-12">
                    <input
                      type="number"
                      id="remainedDays"
                      disabled
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>How many days to encash</label>
              <div className="col-12">
                <input
                  defaultValue={encashment?.noOfDays}
                  type="number"
                  step={0.01}
                  className="form-control"
                  name="noOfDays"
                  ref={register({ required: true })}
                />
              </div>
              <span className="text-danger">
                {errors.noOfDays && <span>This field is required</span>}
              </span>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label>Rate per day</label>
              <div className="col-12">
                <input
                  defaultValue={encashment?.rate}
                  type="number"
                  step={0.01}
                  className="form-control"
                  name="rate"
                  ref={register({ required: true })}
                />
              </div>
              <span className="text-danger">
                {errors.rate && <span>This field is required</span>}
              </span>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <input
            type="submit"
            name="time"
            className="btn btn-primary"
            disabled={isLoading}
            value={
              isLoading
                ? "Please wait..."
                : fetchLeaveTypes.isFetching
                ? "Loading..."
                : "Submit"
            }
          />
        </div>
      </form>
    </>
  );
};

export default AddEncashment;
