import { ENDPIONTS } from "@api";
import { useFetch } from "@hooks";
import { ComplexHeader, MyBarLoader, MyRingLoader, Table } from "@shared";
import React, { useState } from "react";
import { useEffect } from "react";

const LeavesDashboard = () => {
  const fetchData = useFetch<{ [key: string]: { [key: string]: any }[] }>(
    {
      endPoint: ENDPIONTS.leavesDashboard,
    },
    {}
  );

  useEffect(() => {}, [fetchData.isFetching]);

  const table1Headers: ComplexHeader[] = [
    { key: "name", title: "Leave Type" },
    { key: "count", title: "count" },
    { key: "days", title: "days" },
  ];

  const table2Headers: ComplexHeader[] = [
    { key: "name", title: "Name" },
    { key: "leave_Type", title: "leave Type" },
    { key: "from_Date", title: "from", format: "date" },
    { key: "to_Date", title: "to", format: "date" },
    { key: "daysRemaining", title: "days Remaining" },
  ];

  const table3Headers: ComplexHeader[] = [
    { key: "branch", title: "Branch" },
    { key: "count", title: "count" },
    { key: "days", title: "days" },
  ];

  const table4Headers: ComplexHeader[] = [
    { key: "name", title: "Name" },
    { key: "count", title: "count" },
    { key: "days", title: "days" },
  ];

  return (
    <div className="content container-fluid">
      <div className="page-header">
        <div className="row">
          <div className="col-sm-12">
            <h3 className="page-title">Dashboards</h3>
            <ul className="breadcrumb">
              <li className="breadcrumb-item active">Leaves Dashboard</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4 text-center">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title">Top 5 Common Leave Types</h3>
                  <Table
                    class="table table-striped table-hover mb-0"
                    data={fetchData?.data?.table1 ?? []}
                    headers={table1Headers}
                    isFetchingPage={fetchData.isFetching}
                    showCounter
                    paginationClass="row mt-3"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-8 text-center">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title">Employees Nearing Leave Expiry (Below 7 days)</h3>
                  <Table
                    class="table table-striped table-hover mb-0"
                    data={fetchData?.data?.table2 ?? []}
                    headers={table2Headers}
                    isFetchingPage={fetchData.isFetching}
                    showCounter
                    paginationClass="row mt-3"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4 text-center">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title">Leave Distribution by Branch</h3>
                  <Table
                    class="table table-striped table-hover mb-0"
                    data={fetchData?.data?.table3 ?? []}
                    headers={table3Headers}
                    isFetchingPage={fetchData.isFetching}
                    showCounter
                    paginationClass="row mt-3"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-8 text-center">
              <div className="card">
                <div className="card-body">
                  <h3 className="card-title">Top 10 Employees with the Highest leaves count </h3>
                  <Table
                    class="table table-striped table-hover mb-0"
                    data={fetchData?.data?.table4 ?? []}
                    headers={table4Headers}
                    isFetchingPage={fetchData.isFetching}
                    showCounter
                    paginationClass="row mt-3"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeavesDashboard;
