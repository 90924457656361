import { ENDPIONTS, httpService, PaginatedResult } from "@api";
import { AddEncashment } from "@components";
import { useFetch } from "@hooks";
import { Encashment } from "@models";
import { Action, ComplexHeader, Table } from "@shared";
import React, { useEffect, useState } from "react";
import { RiAddFill } from "react-icons/ri";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

interface Props {
  employeeId?: number;
}

const ListEncashments = ({ employeeId }: Props) => {
  const MySwal = withReactContent(Swal);

  const [reload, setReload] = useState<boolean | undefined>(undefined);

  const fetchData = useFetch<PaginatedResult<Encashment>>(
    {
      endPoint: ENDPIONTS.employeeEncashments,
      id: employeeId,
      reload: reload,
    },
    new PaginatedResult<Encashment>()
  );

  useEffect(() => {}, [fetchData?.isFetching]);

  const headers: ComplexHeader[] = [
    { key: "leaveType?.name", title: "Name" },
    { key: "noOfDays", title: "number Of Days" },
    { key: "rate", title: "rate" },
    { key: "total", title: "total" },
    { key: "createdAt", title: "@", format: "date" },
    {
      key: "paymentDate",
      title: "paymentDate ",
      //   format: "date",
      formatter: (paymentDate: string) =>
        paymentDate ? new Date(paymentDate).toLocaleDateString() : "",
    },
  ];

  const actions: Action[] = [
    {
      key: "",
      actionType: "badge",
      click: (l: Encashment) => {
        onPayClick(l);
      },
      title: "Pay",
      color: "primary",
      disable: (v: Encashment) => v.paymentDate !== null,
    },
    {
      key: "",
      actionType: "badge",
      click: (l: Encashment) => {
        onCancelClick(l);
      },
      title: "Cancel",
      color: "danger",
      disable: (v: Encashment) => v.paymentDate !== null,
    },
  ];

  const onCancelClick = (l: Encashment) => {
    if (l.paymentDate) return;

    Swal.fire({
      title: "Cancel Employee Encashment",
      text: `By clicking the confirm button, this record will removed permanently.`,
      icon: "warning",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, I confirm!",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const res = await httpService(ENDPIONTS.encashments).delete(l.id);
        if (res.status === 204) {
          setReload((prev) => (prev === undefined ? true : !prev));
          Swal.fire(
            "Done!",
            "Encashment record has canceled successfully.",
            "success"
          );
        } else {
          Swal.fire(
            "Oops!",
            "An error occurred, please try again later.",
            "error"
          );
        }
      },
    });
  };

  const onPayClick = (l: Encashment) => {
    if (l.paymentDate) return;

    Swal.fire({
      title: "Pay Employee Encashment",
      text: `By clicking the confirm button, this record will be marked as paid.`,
      icon: "warning",
      allowEscapeKey: false,
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, I confirm!",
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        const res = await httpService(ENDPIONTS.payEncashments).update(
          l.id,
          {}
        );
        if (res.status === 204) {
          setReload((prev) => (prev === undefined ? true : !prev));
          Swal.fire(
            "Done!",
            "Encashment record has been marked as paid successfully.",
            "success"
          );
        } else {
          Swal.fire(
            "Oops!",
            "An error occurred, please try again later.",
            "error"
          );
        }
      },
    });
  };

  const onButtonClick = (e: any, encashment?: Encashment) => {
    if (!employeeId) return;
    e !== null && e.preventDefault();

    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 800,
      html: (
        <AddEncashment
          employeeId={employeeId}
          encashment={encashment}
          callback={() =>
            setReload((prev) => (prev === undefined ? true : !prev))
          }
        />
      ),
    });
  };

  return (
    <div className="col-10 offset-1 d-flex">
      <div className="card profile-box flex-fill">
        <div className="card-body">
          <div className="d-flex justify-content-between mb-1">
            <h3>Leaves</h3>
            <div className="d-flex justify-content-end">
              <button
                className={`btn btn-sm btn-outline-info rounded-circle m-1 float-right`}
                onClick={(e) => onButtonClick(e)}
              >
                <RiAddFill />
              </button>
            </div>
          </div>

          <div className="row" id="leavescontents">
            <div
              className="d-flex justify-content-end d-none"
              id="DocumentHeader"
            >
              <small>{`Printed on: ${new Date()}`}</small>
            </div>
            <Table
              actions={actions}
              class="table table-striped table-hover mb-0"
              data={fetchData.data ?? []}
              headers={headers}
              isFetchingPage={fetchData.isFetching}
              showCounter
              // onPageChange={handlePageChange}
              paginationClass="row mt-3"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListEncashments;
