import { BASE_CURRENCY, ENDPIONTS, PaginatedResult } from "@api";
import { AddOverTime } from "@components";
import { useFetch } from "@hooks";
import { OverTime } from "@models";
import { Action, ComplexHeader, Table } from "@shared";
import { AllowanceStatus, FetchOverTimesDTO } from "@viewModels";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

interface Props {
  employeeId?: number;
}

const ListOverTimes: React.FC<Props> = ({ employeeId }) => {
  const MySwal = withReactContent(Swal);

  const [reload, setReload] = useState<boolean | undefined>(undefined);

  const fetchData = useFetch<PaginatedResult<FetchOverTimesDTO>>(
    { endPoint: ENDPIONTS.overTimes, id: employeeId, reload: reload },
    new PaginatedResult<FetchOverTimesDTO>()
  );

  useEffect(() => {}, [fetchData?.isFetching]);

  const headers: ComplexHeader[] = [
    { key: "employeeName", title: "Name" },
    { key: "overTimeTypeName", title: "Overtime Type" },
    { key: "date", title: "Date", format: "date" },
    { key: "hours", title: "Hours" },
    { key: "rate", title: "Rate" },
    {
      key: "amount",
      title: "amount",
      format: "currency",
      currency: () => BASE_CURRENCY,
    },
    {
      key: "status",
      title: "status",
      cellClass: (u: FetchOverTimesDTO) =>
        u.status === AllowanceStatus.Finished.toLowerCase()
          ? "badge rounded-pill bg-secondary"
          : u.status === AllowanceStatus.Ongoing.toLowerCase()
          ? "badge rounded-pill bg-info"
          : "badge rounded-pill bg-danger",
    },
    { key: "description", title: "description" },
    { key: "createdAt", title: "@", format: "date" },
  ];

  const actions: Action[] = [
    {
      key: "",
      actionType: "badge",
      click: (d: FetchOverTimesDTO) => {
        onButtonClick(null, d);
      },
      title: "Edit",
      color: "warning",
      disable: (d: FetchOverTimesDTO) =>
        d.status.toLowerCase() !== AllowanceStatus.Ongoing.toLowerCase(),
    },
  ];

  const onButtonClick = (e: any, a?: FetchOverTimesDTO) => {
    if (!employeeId) return;
    e !== null && e.preventDefault();

    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 600,
      html: (
        <AddOverTime
          employeeId={employeeId}
          overtime={a}
          callback={() =>
            setReload((prev) => (prev === undefined ? true : !prev))
          }
        />
      ),
    });
  };
  return (
    <div className="col-12 d-flex">
      <div className="card profile-box flex-fill">
        <div className="card-body">
          <h3 className="card-title">
            OverTimes
            <a
              href="!#"
              onClick={(e) => onButtonClick(e)}
              className="edit-icon"
              data-bs-toggle="modal"
              data-bs-target="#personal_info_modal"
            >
              <i className="fa fa-plus" />
            </a>
          </h3>
          <Table
            actions={actions}
            class="table table-striped table-hover mb-0"
            data={fetchData?.data ?? []}
            headers={headers}
            isFetchingPage={fetchData.isFetching}
            showCounter
            // onPageChange={handlePageChange}
            paginationClass="row mt-3"
          />
        </div>
      </div>
    </div>
  );
};

export default ListOverTimes;
