import { ENDPIONTS, httpService } from "@api";
import { PayrollPrep } from "@models";
import { PayrollPreviewVM } from "@viewModels";
import { BranchSelection } from "@widgets";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const FilterPayroll = () => {
  const navigate = useNavigate();

  const { register, handleSubmit, errors } = useForm();

  const [selectedBranchId, setSelectedBranchId] = useState<number>();
  const [forMonth, setForMonth] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onSearch = async (e: any) => {
    e.preventDefault();

    if (!selectedBranchId || !forMonth) return;

    setIsLoading(true);

    const params = new URLSearchParams();
    params.set("branchId", selectedBranchId?.toString());
    params.set("forMonth", forMonth);

    const res = await httpService(ENDPIONTS.payrollPreps, params).getAll();
    if (res.status === 200) {
      const payrollPrep = res.data as PayrollPrep[];
      if (payrollPrep.length > 0) {
        // It means payrollPrep exists, so redirect directly to payroll preview page.
        navigate(`/payrolls/preview/${selectedBranchId}/${payrollPrep[0].id}`);
      } else {
        //1st. get the payroll Preview
        const p = new URLSearchParams();
        p.set("branchId", selectedBranchId?.toString());

        const payrollPreview = await httpService(
          ENDPIONTS.payrollPreview,
          p
        ).getAll();
        if (
          payrollPreview.status === 200 &&
          (payrollPreview.data as PayrollPreviewVM[]).length > 0
        ) {
          //2nd. Create new payrollPrep
          var obj = {
            branchId: selectedBranchId,
            forMonth: forMonth,
            payrollList: payrollPreview.data,
          };

          const savePP = await httpService(ENDPIONTS.payrollPreps).post(obj);
          if (savePP.status === 200) {
            // 3rd. PayrollPrep is created. Now, redirect to payroll preview page.
            navigate(`/payrolls/preview/${selectedBranchId}/${savePP.data}`);
          }
        }
      }
    }

    setIsLoading(false);
  };

  return (
    <>
      <div className="col-10 offset-1 d-flex mt-5">
        <div className="card profile-box flex-fill">
          <div className="card-body">
            <h3 className="card-title">Search Payroll</h3>
            <div className="row">
              <div className="col">
                <div className="form-group">
                  <label>Branch</label>
                  <BranchSelection
                    endpoint={ENDPIONTS.payrollBranches}
                    returnValue={(id: number) => setSelectedBranchId(id)}
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-group">
                  <label>Payroll Month</label>
                  <input
                    value={forMonth}
                    type="month"
                    className="form-control"
                    onChange={(e) => setForMonth(e.target.value)}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <input
                  type="submit"
                  name="time"
                  className="btn btn-primary"
                  disabled={isLoading}
                  value={isLoading ? "Please wait..." : "Search"}
                  onClick={onSearch}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterPayroll;
