import { ENDPIONTS, Operation, PaginatedResult } from "@api";
import { NewKRA } from "@components";
import { useFetch } from "@hooks";
import { KRA } from "@models";
import {
  Action,
  ComplexHeader,
  Filterable,
  Table,
  TableVerticalConfigs,
} from "@shared";
import React, { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const ListKRAs = () => {
  const [queryStrings] = useSearchParams();
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();

  const [reload, setReload] = useState<boolean | undefined>(undefined);

  const fetchKRAs = useFetch<PaginatedResult<KRA>>(
    {
      endPoint: ENDPIONTS.kras,
      queryStrings: queryStrings,
      reload: reload,
    },
    new PaginatedResult<KRA>()
  );

  useEffect(() => {}, [fetchKRAs?.isFetching]);

  const headers: ComplexHeader[] = [
    { key: "name", title: "KRA" },
    { key: "createdBy", title: "By" },
    { key: "createdAt", title: "@", format: "date" },
  ];

  const filters: Filterable<KRA>[] = [
    { key: "name", title: "name", format: "text", operation: Operation.like },
  ];

  const actions: Action[] = [
    {
      key: "",
      actionType: "badge",
      click: (d: KRA) => {
        onAddUserClickHandler(d);
      },
      title: "Edit",
      color: "warning",
    },
  ];

  const onAddUserClickHandler = (d?: KRA) => {
    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 600,
      html: (
        <NewKRA
          selectedKRA={d}
          callback={() =>
            setReload((prev) => (prev === undefined ? true : !prev))
          }
        />
      ),
    });
  };

  const handlePageChange = (page: number, size: number) => {
    queryStrings.set("size", size.toString());
    queryStrings.set("page", (page - 1).toString());

    navigate({ search: `?${queryStrings.toString()}` });
  };
  return (
    <div className="content container-fluid">
      <div className="row align-items-center">
        <div className="col">
          <h3 className="page-title">Key Result Areas</h3>
        </div>
        <div className="col-auto float-end ms-auto">
          <button
            className="btn btn-sm btn-primary rounded-circle m-1"
            onClick={() => onAddUserClickHandler()}
          >
            <FiPlus />
          </button>
          <TableVerticalConfigs filters={filters} />
        </div>
      </div>
      <div className="col-8 offset-2 mt-3">
        <Table
          actions={actions}
          class="table table-striped table-hover mb-0"
          data={fetchKRAs?.data ?? []}
          headers={headers}
          isFetchingPage={fetchKRAs.isFetching}
          showCounter
          onPageChange={handlePageChange}
          paginationClass="row mt-3"
        />
      </div>
    </div>
  );
};

export default ListKRAs;
