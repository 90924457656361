import { ENDPIONTS, httpService } from "@api";
import React, { useState } from "react";
import { useForm } from "react-hook-form";

interface Props {
  empId: number;
  callback?: () => void;
}
const AddTermination = ({ empId, callback }: Props) => {
  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: any, e: any) => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append("file", data.file[0]);
    formData.append("employeeId", empId.toString());
    formData.append("reason", data.reason);
    formData.append("remarks", data.remarks);

    const res = await httpService(ENDPIONTS.terminateEmployee).update(empId, formData);

    callback?.();
    setIsLoading(false);
    e.target.reset();
  };

  return (
    <>
      <h4>Employee Termination</h4>
      <hr />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="form-group">
            <label>Termination Reason</label>
            <select className="form-control select" name="reason" ref={register({ required: true })}>
              <option></option>
              <option value="Discharge">Discharge</option>
              <option value="Resignation">Resignation</option>
              <option value="Layoff">Layoff</option>
              <option value="PermanentDisability">PermanentDisability</option>
              <option value="Retirement">Retirement</option>
              <option value="Death">Death</option>
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label htmlFor="file">Select file</label>
              <input type="file" name="file" id="file" className="form-control" ref={register({ required: true })} />
              <span className="text-danger">{errors.file && <span>This field is required</span>}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label htmlFor="reason">Remarks</label>
              <textarea
                className="form-control mb-4"
                name="remarks"
                id="remarks"
                aria-label="With textarea"
                ref={register({ required: true })}
              ></textarea>
              <span className="text-danger">{errors.reason && <span>This field is required</span>}</span>
            </div>
          </div>
        </div>

        <input
          type="submit"
          name="time"
          className="btn btn-primary"
          disabled={isLoading}
          value={isLoading ? "Please wait..." : "Terminate"}
        />
      </form>
    </>
  );
};

export default AddTermination;
