import { ENDPIONTS, httpService, PaginatedResult, PagingOptions } from "@api";
import { useFetch } from "@hooks";
import {
  Branch,
  Department,
  Designation,
  Employee,
  PayrollGroup,
  Shift,
  SubBranch,
} from "@models";
import { DateHelpers, MyBarLoader } from "@shared";
import { CreateEmployee } from "@viewModels";
import { BranchSelection } from "@widgets";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";

interface Props {
  callback?: () => void;
  employee?: Employee;
}

const jobGrage = [
  "Executive",
  "Administration",
  "Management",
  "Operations",
  "Unskilled",
  "None Management",
];

const AddEmployee: React.FC<Props> = ({ callback, employee }) => {
  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDesignations, setIsLoadingDesignations] = useState(false);
  const [isLoadingSubBranches, setIsLoadingSubBranches] = useState(false);
  const [fetchDesignations, setFetchDesignations] = useState<Designation[]>([]);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(0);
  const [selectedBranchId, setSelectedBranchId] = useState(
    employee?.branchId ?? 0
  );
  const [subBranches, setsubBranches] = useState<SubBranch[]>([]);

  const fetchBranches = useFetch<PaginatedResult<Branch>>(
    { endPoint: ENDPIONTS.branches, ignorePagination: true },
    new PaginatedResult<Branch>()
  );
  const fetchDepartments = useFetch<PaginatedResult<Department>>(
    { endPoint: ENDPIONTS.departments, ignorePagination: true },
    new PaginatedResult<Department>()
  );
  const fetchPayrollGroups = useFetch<PaginatedResult<PayrollGroup>>(
    { endPoint: ENDPIONTS.payrollGroups, ignorePagination: true },
    new PaginatedResult<PayrollGroup>()
  );
  const fetchShifts = useFetch<PaginatedResult<Shift>>(
    { endPoint: ENDPIONTS.shifts },
    new PaginatedResult<Shift>()
  );

  useEffect(() => {}, [
    fetchBranches.isFetching,
    fetchDepartments.isFetching,
    fetchPayrollGroups.isFetching,
    fetchShifts.isFetching,
  ]);

  useLayoutEffect(() => {
    if (employee?.id) {
      setSelectedDepartmentId(employee.departmentId);
    }

    return () => {
      employee = undefined;
    };
  }, [employee]);

  useEffect(() => {
    (async function () {
      setIsLoadingDesignations(true);
      const options = new PagingOptions(1, -1);
      options.filter<Designation>((f) =>
        f.eq("departmentId", selectedDepartmentId)
      );
      const res = await httpService(ENDPIONTS.designations, options).getAll();
      if (res.status === 200) {
        setFetchDesignations(res?.data?.items);
      }
      setIsLoadingDesignations(false);
    })();
  }, [selectedDepartmentId]);

  useEffect(() => {
    if (selectedBranchId > 0) {
      (async function () {
        setIsLoadingSubBranches(true);

        const res = await httpService(ENDPIONTS.subBranches).getById(
          selectedBranchId
        );
        if (res.status === 200) setsubBranches(res.data);

        setIsLoadingSubBranches(false);
      })();
    }
  }, [selectedBranchId]);

  const onSubmit = async (data: any, e: any) => {
    setIsLoading(true);

    let emp: CreateEmployee = {
      name: data.name,
      phone: data.phone,
      email: data.email,
      birthDate: data.birthDate,
      ageGroup: data.ageGroup,
      jobGrade: data.jobGrade,
      typeOfContract: data.typeOfContract,
      sourceOfIncome: data.sourceOfIncome,
      departmentId: +data.departmentId,
      designationId: +data.designationId,
      branchId: +data.branchId,
      payrollGroupId: +data.payrollGroupId,
      dateOfEmployment: data.dateOfEmployment,
      gender: data.gender,
      employeeId: data.employeeId,
      subBranchId: data?.subBranchId ? data.subBranchId : undefined,
    };

    if (employee?.id) {
      // IT is edit.
      emp.id = employee.id; // add the id field to the obove object since it is update Op.
      emp.accountNumber = data.accountNumber;
      emp.shiftId = data.shiftId;

      await httpService(ENDPIONTS.employees).update(employee.id, emp);
    } else {
      // It is save.
      await httpService(ENDPIONTS.employees).post(emp);
    }

    callback?.();
    setIsLoading(false);
    e.target.reset();
  };

  // const onBranchSelection = async (id: number) => {
  //   const res = await httpService(ENDPIONTS.SubSubBranches).getById(id);
  //   if (res.status === 200) setsubBranches(res.data);
  // };

  if (
    fetchBranches.isFetching ||
    fetchDepartments.isFetching ||
    fetchPayrollGroups.isFetching ||
    fetchShifts.isFetching
  )
    return <MyBarLoader />;

  return (
    <>
      <h4>{employee?.id ? "Edit Employee" : "Add New Employee"}</h4>
      <hr />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Full Name</label>
              <div className="col-12">
                <input
                  type="text"
                  defaultValue={employee?.name}
                  className="form-control"
                  placeholder="e.g Abdi Abdilahi"
                  name="name"
                  ref={register({ required: true })}
                />
              </div>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label>Phone</label>
              <div className="col-12">
                <input
                  type="text"
                  defaultValue={employee?.phone}
                  className="form-control"
                  placeholder="e.g 6x xxx xxxx"
                  name="phone"
                  ref={register({ required: true })}
                />
              </div>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label>employee Id</label>
              <div className="col-12">
                <input
                  type="text"
                  defaultValue={employee?.employeeId ?? ""}
                  className="form-control"
                  placeholder="e.g Emp.876"
                  name="employeeId"
                  ref={register()}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <div className="form-group">
              <label>Email Address</label>
              <div className="col-12">
                <input
                  type="email"
                  defaultValue={employee?.email ?? ""}
                  className="form-control"
                  placeholder="e.g name@mail.com"
                  name="email"
                  ref={register({ required: true })}
                />
              </div>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label>Date of Birth</label>
              <div className="col-12">
                <input
                  type="date"
                  defaultValue={DateHelpers.setInputDate(employee?.birthDate)}
                  className="form-control"
                  name="birthDate"
                  ref={register({ required: true })}
                />
              </div>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label>Age Group</label>
              <select
                className="form-control select"
                name="ageGroup"
                ref={register()}
              >
                <option></option>
                <option selected={employee?.ageGroup === "20-29"} value="20-29">
                  20-29
                </option>
                <option selected={employee?.ageGroup === "30-39"} value="30-39">
                  30-39
                </option>
                <option selected={employee?.ageGroup === "40-49"} value="40-49">
                  40-49
                </option>
                <option selected={employee?.ageGroup === "50+"} value="50+">
                  50+
                </option>
              </select>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label>Gender</label>
              <select
                className="form-control select"
                name="gender"
                ref={register({ required: true })}
              >
                <option></option>
                <option selected={employee?.gender === "Female"} value="Female">
                  Female
                </option>
                <option selected={employee?.gender === "Male"} value="Male">
                  Male
                </option>
              </select>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <div className="form-group">
              <label>Department</label>
              <select
                onChange={(e) => setSelectedDepartmentId(+e.target.value)}
                className="form-control select"
                name="departmentId"
                ref={register({ required: true })}
              >
                <option></option>
                {fetchDepartments?.data?.items?.map((d: Department, i: any) => {
                  return (
                    <option
                      selected={employee?.departmentId === d.id}
                      key={i}
                      value={d.id}
                    >
                      {d.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label>Designation</label>
              <select
                disabled={selectedDepartmentId === 0}
                className="form-control select"
                name="designationId"
                ref={register({ required: true })}
              >
                {fetchDepartments?.data?.items?.length < 0 ||
                isLoadingDesignations ? (
                  <option>Loading Data...</option>
                ) : (
                  <>
                    <option></option>
                    {fetchDesignations?.map((d: Designation, i: any) => {
                      return (
                        <option
                          selected={employee?.designationId === d.id}
                          key={i}
                          value={d.id}
                        >
                          {d.name}
                        </option>
                      );
                    })}
                  </>
                )}
              </select>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label>job Grade</label>
              <select
                className="form-control select"
                name="jobGrade"
                ref={register()}
              >
                <option></option>
                {jobGrage.map((g, idx) => {
                  return (
                    <option selected={employee?.jobGrade === g} value={g}>
                      {g}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <div className="form-group">
              <label>Branch</label>
              <BranchSelection
                register={register}
                isSelectedOption={employee?.branchId}
                returnValue={(id: number) => setSelectedBranchId(id)}
              />
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label>Sub-branch</label>
              <select
                className="form-control select"
                name="subBranchId"
                ref={register()}
                disabled={selectedBranchId === 0}
              >
                {isLoadingSubBranches ? (
                  <option>Loading Data...</option>
                ) : (
                  <>
                    {" "}
                    <option></option>
                    {subBranches?.map((p, i: any) => {
                      return (
                        <option
                          selected={employee?.subBranchId === p.id}
                          key={i}
                          value={p.id}
                        >
                          {p.name}
                        </option>
                      );
                    })}
                  </>
                )}
              </select>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label>Payroll Group</label>
              <select
                className="form-control select"
                name="payrollGroupId"
                ref={register()}
              >
                <option></option>
                {fetchPayrollGroups?.data?.items?.map(
                  (p: PayrollGroup, i: any) => {
                    return (
                      <option
                        selected={employee?.payrollGroupID === p.id}
                        key={i}
                        value={p.id}
                      >
                        {p.name}
                      </option>
                    );
                  }
                )}
              </select>
            </div>
          </div>
          {employee?.id && (
            <div className="col">
              <div className="form-group">
                <label>Account No.</label>
                <div className="col-12">
                  <input
                    type="text"
                    defaultValue={employee?.accountNumber ?? ""}
                    className="form-control"
                    placeholder="e.g 6x xxx xxxx"
                    name="accountNumber"
                    ref={register({ required: true })}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="row mt-3">
          <div className="col">
            <div className="form-group">
              <label>Date of Employment</label>
              <div className="col-12">
                <input
                  type="date"
                  defaultValue={DateHelpers.setInputDate(
                    employee?.dateOfEmployment
                  )}
                  className="form-control"
                  name="dateOfEmployment"
                  ref={register({ required: true })}
                />
              </div>
            </div>
          </div>
          {employee?.id && (
            <div className="col">
              <div className="form-group">
                <label>Working Shift</label>
                <select
                  className="form-control select"
                  name="shiftId"
                  ref={register({ required: true })}
                >
                  <option></option>
                  {fetchShifts?.data?.items?.map((s: Shift, i: any) => {
                    return (
                      <option
                        selected={employee?.shiftId === s.id}
                        key={i}
                        value={s.id}
                      >
                        {s.shiftName}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          )}
        </div>

        <input
          type="submit"
          name="time"
          className="btn btn-primary"
          disabled={isLoading}
          value={
            isLoading || isLoadingDesignations || isLoadingSubBranches
              ? "Please wait..."
              : employee?.id
              ? "Update"
              : "Register"
          }
        />
      </form>
    </>
  );
};

export default AddEmployee;
