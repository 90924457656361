import React from "react";

interface props {
  title?: string;
}
const PaperHeader = ({ title }: props) => {
  return (
    <div className="d-none">
      <div className="d-flex justify-content-center">
        <img
          src="/assets/img/DHH.png"
          style={{ width: "120px" }}
          alt="DHH"
        />
      </div>
      <hr />
      {title && (
        <div className="d-flex justify-content-center mb-1" id="DocumentHeader">
          <h3>{title}</h3>
        </div>
      )}
      <div className="d-flex justify-content-end mb-3" id="DocumentHeader">
        <small>{`Printed on: ${new Date().toLocaleDateString()}`}</small>
      </div>
    </div>
  );
};

export default PaperHeader;
