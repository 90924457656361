import { ENDPIONTS, httpService, PaginatedResult } from "@api";
import { AppraisalCycle, AppraisalTemplate } from "@models";
import {
  AppraisalCycleType,
  PostAppraisalCycleDTO,
  PutAppraisalCycleDTO,
} from "@viewModels";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

import {
  format,
  addDays,
  endOfWeek,
  startOfYear,
  addMonths,
  subDays,
  endOfMonth,
  endOfYear,
} from "date-fns";
import { useFetch } from "@hooks";

interface Interval {
  no: string;
  startDate: string;
  endDate: string;
}

const getIntervals = (
  cycleType: AppraisalCycleType | undefined
): Interval[] => {
  switch (cycleType) {
    case AppraisalCycleType.Weekly:
      return getWeeksInYear(new Date().getFullYear());

    case AppraisalCycleType.Monthly:
      return getMonthsInYear(new Date().getFullYear());

    case AppraisalCycleType.Quarterly:
      return getQuartersInYear(new Date().getFullYear());

    case AppraisalCycleType.Yearly:
      return getYear(new Date().getFullYear());

    default:
      return [];
  }
};

interface Props {
  selectedAppraisalCycleId?: number;
  callback?: () => void;
}

const AddAppraisalCycle = ({ callback, selectedAppraisalCycleId }: Props) => {
  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedTemplate, setSelectedTemplate] =
    useState<AppraisalTemplate | null>(null);
  const [intervals, setIntervals] = useState<Interval[]>([]);
  const [selectedInterval, setSelectedInterval] = useState<Interval | null>(
    null
  );

  const fetchTemplates = useFetch<PaginatedResult<AppraisalTemplate>>(
    { endPoint: ENDPIONTS.appraisalTemplates },
    new PaginatedResult<AppraisalTemplate>()
  );

  useEffect(() => {
    setIntervals(getIntervals(selectedTemplate?.cycleType));
  }, [selectedTemplate]);

  const onSubmit = async (data: any, e: any) => {
    if (!selectedInterval) return;
    setIsLoading(true);

    const appraisalCycle: Partial<
      PostAppraisalCycleDTO & PutAppraisalCycleDTO
    > = {
      appraisalTemplateId: +data.appraisalTemplateId,
      startsAt: selectedInterval.startDate,
      endsAt: selectedInterval.endDate,
    };

    if (selectedAppraisalCycleId) {
      appraisalCycle.id = selectedAppraisalCycleId;
      const res = await httpService(ENDPIONTS.appraisalCycles).update(
        selectedAppraisalCycleId,
        appraisalCycle
      );
    } else {
      const res = await httpService(ENDPIONTS.appraisalCycles).post(
        appraisalCycle
      );
      if (res.status === 201) {
        Swal.fire({
          icon: "success",
          text: "New AppraisalCycle has been successfully registered.",
          showConfirmButton: false,
        });
      }
    }

    callback?.();
    setIsLoading(false);
    e.target.reset();
  };

  const onTemplateSelection = (id: number) => {
    const res = fetchTemplates.data.items.filter((t) => t.id === id)[0];
    setSelectedTemplate(res);
  };

  return (
    <>
      <h3>New Appraisal Cycle</h3>
      <hr />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Select Template</label>
              <select
                className="form-control select"
                name="appraisalTemplateId"
                ref={register({ required: true })}
                onChange={(e) => onTemplateSelection(+e.target.value)}
              >
                <option></option>
                {fetchTemplates?.data?.items?.map(
                  (r: AppraisalTemplate, i: any) => {
                    return (
                      <option key={i} value={r.id}>
                        {r.name}
                      </option>
                    );
                  }
                )}
              </select>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label>Select Cycle</label>
              <select
                className="form-control select"
                name="departmentId"
                ref={register({ required: true })}
                onChange={(e) =>
                  setSelectedInterval(
                    intervals.filter((i) => i.no === e.target.value)[0]
                  )
                }
              >
                <option></option>
                {intervals?.map((r: Interval, i: any) => {
                  return (
                    <option key={i} value={r.no}>
                      {r.no}: {r.startDate} - {r.endDate}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>

        <input
          type="submit"
          className="btn btn-primary"
          disabled={isLoading}
          value={
            isLoading
              ? "Please wait..."
              : selectedAppraisalCycleId
              ? "Update"
              : "Add Cycle"
          }
        />
      </form>
    </>
  );
};

export default AddAppraisalCycle;

const getWeeksInYear = (year: number): Interval[] => {
  const weeks: Interval[] = [];
  let startDate = startOfYear(new Date(year, 0, 1));
  let currentDate = startDate;

  while (format(currentDate, "yyyy") === String(year)) {
    const weekNumber = parseInt(format(currentDate, "w"), 10);
    const endDate = endOfWeek(currentDate);
    weeks.push({
      no: "W" + weekNumber,
      startDate: format(currentDate, "yyyy-MM-dd"),
      endDate: format(endDate, "yyyy-MM-dd"),
    });
    currentDate = addDays(endDate, 1);
  }

  return weeks;
};

const getQuartersInYear = (year: number): Interval[] => {
  const quarters: Interval[] = [];
  let startDate = startOfYear(new Date(year, 0, 1));

  for (let i = 0; i < 4; i++) {
    const quarterNumber = i + 1;
    const startMonth = i * 3;
    const quarterStartDate = addMonths(startDate, startMonth);
    const quarterEndDate = subDays(addMonths(quarterStartDate, 3), 1);
    quarters.push({
      no: "Q" + quarterNumber,
      startDate: format(quarterStartDate, "yyyy-MM-dd"),
      endDate: format(quarterEndDate, "yyyy-MM-dd"),
    });
  }

  return quarters;
};

const getMonthsInYear = (year: number): Interval[] => {
  const months: Interval[] = [];
  let startDate = startOfYear(new Date(year, 0, 1));

  for (let i = 0; i < 12; i++) {
    const monthNumber = i + 1;
    const monthStartDate = addMonths(startDate, i);
    const monthEndDate = endOfMonth(monthStartDate);
    months.push({
      no: "M" + monthNumber,
      startDate: format(monthStartDate, "yyyy-MM-dd"),
      endDate: format(monthEndDate, "yyyy-MM-dd"),
    });
  }

  return months;
};

const getYear = (year: number): Interval[] => {
  const years: Interval[] = [];
  const startDate = startOfYear(new Date(year, 0, 1));
  const endDate = endOfYear(startDate);
  years.push({
    no: "Y" + 1,
    startDate: format(startDate, "yyyy-MM-dd"),
    endDate: format(endDate, "yyyy-MM-dd"),
  });

  return years;
};
