import { ENDPIONTS, Operation, PaginatedResult } from "@api";
import { PaperHeader } from "@components";
import { useFetch } from "@hooks";
import { ComplexHeader, Filterable, Table, TableVerticalConfigs } from "@shared";
import { FetchAuditsDTO } from "@viewModels";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const AuditReport = () => {
  const [queryStrings] = useSearchParams();
  const navigate = useNavigate();

  const fetchData = useFetch<PaginatedResult<FetchAuditsDTO>>(
    {
      endPoint: ENDPIONTS.audits,
      queryStrings: queryStrings,
    },
    new PaginatedResult<FetchAuditsDTO>()
  );

  useEffect(() => {}, [fetchData?.isFetching]);

  const headers: ComplexHeader[] = [
    { key: "type", title: "type" },
    { key: "event", title: "event" },
    { key: "remarks", title: "remarks" },
    { key: "createdBy", title: "createdBy" },
    { key: "createdAt", title: "createdAt", format: "date" },
  ];

  const handlePageChange = (page: number, size: number) => {
    queryStrings.set("size", size.toString());
    queryStrings.set("page", (page - 1).toString());

    navigate({ search: `?${queryStrings.toString()}` });
  };

  const filters: Filterable<any>[] = [
    { key: "createdAt", title: "created at", format: "dateRange" },
    {
      key: "type",
      title: "Type",
      format: "select",
      data: [
        ...type.map((d) => ({
          id: d,
          name: d,
        })),
      ],
    },
    {
      key: "event",
      title: "Event",
      format: "select",
      data: [
        ...event.map((d) => ({
          id: d,
          name: d,
        })),
      ],
    },
  ];

  return (
    <div className="content container-fluid">
      <div className="row align-items-center">
        <div className="d-flex justify-content-between mb-1">
          <div className="col-4">
            <h3 className="page-title">System Audit Report</h3>
          </div>
        </div>
      </div>

      <div className="col-12 mt-3" id="reportcontents">
        <PaperHeader title="Terminations Report" />
        <div className="row mb-3">
          <div className="col-auto float-end ms-auto">
            <TableVerticalConfigs
              filters={filters}
              //   exportAsXSLS={onExport}
              //   onPrint={{
              //     ref: "reportcontents",
              //     prePrint: onPrint,
              //   }}
            />
          </div>
        </div>
        <Table
          class="table table-striped table-hover mb-0"
          data={fetchData?.data ?? []}
          headers={headers}
          isFetchingPage={fetchData.isFetching}
          showCounter
          onPageChange={handlePageChange}
          paginationClass="row mt-3"
        />
      </div>
    </div>
  );
};

export default AuditReport;

const type = [
  "Allowance",
  "Allowance Type",
  "Appraisal Cycle",
  "Appraisal",
  "Appraisal Template",
  "Branch",
  "Deduction",
  "Deduction Type",
  "Department",
  "Designation",
  "Document Type",
  "Document",
  "Emergency Contact",
  "Employee",
  "Encashment",
  "Job Application",
  "Job Offer",
  "Leave",
  "Leave Type",
  "OverTime",
  "OverTime Type",
  "Payroll Group",
  "Payroll Prep",
  "Payroll",
  "Role",
  "Shift",
  "SubBranch",
  "User",
];
const event = ["Add", "Clear", "Cancel", "Delete", "Generate", "Pay", "Termination", "Update", "Undo"];
