import {
  ENDPIONTS,
  httpService,
  Operation,
  PaginatedResult,
  PagingOptions,
} from "@api";
import {
  AddAppraisalCycle,
  ChangeCycleStatus,
  GenerateAppraisals,
} from "@components";
import { useFetch } from "@hooks";
import { AppraisalCycle, AppraisalTemplate } from "@models";
import {
  Action,
  ComplexHeader,
  DateHelpers,
  Filterable,
  Table,
  TableVerticalConfigs,
} from "@shared";
import { AppraisalCycleStatus, GetAppraisalCyclesVM } from "@viewModels";
import React, { useEffect, useState } from "react";
import { FiPlus, FiSearch } from "react-icons/fi";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const ListCycles = () => {
  const [queryStrings] = useSearchParams();
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();

  const [reload, setReload] = useState<boolean | undefined>(undefined);
  const [selectedTemplateId, setSelectedTemplateId] = useState<number | null>(
    null
  );
  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear()
  );
  const [isFetching, setIsFetching] = useState(false);
  const [cycles, setCycles] = useState<PaginatedResult<GetAppraisalCyclesVM>>(
    new PaginatedResult<GetAppraisalCyclesVM>()
  );

  const fetchTemplates = useFetch<PaginatedResult<AppraisalTemplate>>(
    { endPoint: ENDPIONTS.appraisalTemplates },
    new PaginatedResult<AppraisalTemplate>()
  );

  useEffect(() => {
    onSearchClickHandler();
  }, [reload]);

  const headers: ComplexHeader[] = [
    { key: "id", title: "Cycle Id#" },
    { key: "templateName", title: "Template" },
    { key: "startsAt", title: "starts At", format: "date" },
    { key: "endsAt", title: "ends At", format: "date" },
    { key: "status", title: "status" },
    { key: "year", title: "year" },
  ];

  const filters: Filterable<GetAppraisalCyclesVM>[] = [
    //   { key: "name", title: "name", format: "text", operation: Operation.like },
  ];

  const actions: Action[] = [
    {
      key: "",
      actionType: "badge",
      click: (a: GetAppraisalCyclesVM) => {
        onDeleteClickHandler(a);
      },
      title: "Delete",
      color: "danger",
      disable: (a: GetAppraisalCyclesVM) => a.hasAppraisals,
    },
    {
      key: "",
      actionType: "badge",
      click: (a: GetAppraisalCyclesVM) => {
        onGenerateAppraisalsClickHandler(a);
      },
      title: "Generate Appraisals",
      color: "dark",
      disable: (a: GetAppraisalCyclesVM) =>
        a.hasAppraisals ||
        DateHelpers.isDateGreaterThanToday(new Date(a.startsAt)),
    },
    {
      key: "",
      actionType: "badge",
      click: (a: GetAppraisalCyclesVM) => {
        navigate(`/appraisal/list/${a.id}`);
      },
      title: "Show Appraisals",
      color: "info",
      disable: (a: GetAppraisalCyclesVM) =>
        a.status === AppraisalCycleStatus.Draft,
    },
    {
      key: "",
      actionType: "badge",
      click: (a: GetAppraisalCyclesVM) => {
        onChangeCycleStatus(a);
      },
      title: "Status",
      color: "warning",
      disable: (a: GetAppraisalCyclesVM) =>
        a.status === AppraisalCycleStatus.Draft,
    },
  ];

  const onChangeCycleStatus = (a: GetAppraisalCyclesVM) => {
    if (a.status === AppraisalCycleStatus.Draft) return;

    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 600,
      html: (
        <ChangeCycleStatus
          cycle={a}
          callback={() =>
            setReload((prev) => (prev === undefined ? true : !prev))
          }
        />
      ),
    });
  };

  const onAddUserClickHandler = (d?: GetAppraisalCyclesVM) => {
    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 600,
      html: (
        <AddAppraisalCycle
          selectedAppraisalCycleId={d?.id}
          callback={() =>
            setReload((prev) => (prev === undefined ? true : !prev))
          }
        />
      ),
    });
  };

  const onDeleteClickHandler = async (a: GetAppraisalCyclesVM) => {
    if (a.hasAppraisals) return;

    setIsFetching(true);
    const res = await httpService(ENDPIONTS.appraisalCycles).delete(a.id);
    if (res.status === 204)
      setReload((prev) => (prev === undefined ? true : !prev));
    setIsFetching(false);
  };

  const onSearchClickHandler = async () => {
    if (!selectedTemplateId || !selectedYear) return;

    setIsFetching(true);
    const options = new PagingOptions(0, 100);
    options.filter<AppraisalCycle>((f) =>
      f.eq("appraisalTemplateId", selectedTemplateId).eq("year", selectedYear)
    );

    const res = await httpService(ENDPIONTS.appraisalCycles, options).getAll();
    if (res.status === 200) setCycles(res.data);
    setIsFetching(false);
  };

  const onGenerateAppraisalsClickHandler = (a: GetAppraisalCyclesVM) => {
    if (
      a.hasAppraisals ||
      DateHelpers.isDateGreaterThanToday(new Date(a.startsAt))
    )
      return;

    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 1000,
      html: (
        <GenerateAppraisals
          templateId={a.appraisalTemplateId}
          cycleId={a.id}
          callback={() =>
            setReload((prev) => (prev === undefined ? true : !prev))
          }
        />
      ),
    });
  };

  return (
    <div className="content container-fluid">
      <div className="row align-items-center">
        <div className="col">
          <h3 className="page-title">Appraisal Cycles</h3>
        </div>
        <div className="col-8">
          <div className="row">
            <div className="col">
              <div className="form-group mb-0 row">
                <label className="col-form-label col-auto">Template</label>
                <div className="col">
                  <select
                    className="form-control form-control-sm select"
                    name="appraisalTemplateId"
                    onChange={(e) => setSelectedTemplateId(+e.target.value)}
                  >
                    <option></option>
                    {fetchTemplates?.data?.items?.map(
                      (r: AppraisalTemplate, i: any) => {
                        return (
                          <option key={i} value={r.id}>
                            {r.name}
                          </option>
                        );
                      }
                    )}
                  </select>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="form-group mb-0 row">
                <label className="col-form-label col-auto">Year</label>
                <div className="col">
                  <input
                    type="number"
                    defaultValue={selectedYear}
                    className="form-control form-control-sm row"
                    onChange={(e) => setSelectedYear(+e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-auto float-end ms-auto">
          <button
            className="btn btn-sm btn-dark rounded-circle m-1"
            onClick={() => onSearchClickHandler()}
          >
            <FiSearch />
          </button>
          <button
            className="btn btn-sm btn-primary rounded-circle m-1"
            onClick={() => onAddUserClickHandler()}
          >
            <FiPlus />
          </button>

          {/* <TableVerticalConfigs filters={filters} /> */}
        </div>
      </div>
      <div className="col-10 offset-1 mt-3">
        <Table
          actions={actions}
          class="table table-striped table-hover mb-0"
          data={cycles ?? []}
          headers={headers}
          isFetchingPage={isFetching}
          showCounter
        />
      </div>
    </div>
  );
};

export default ListCycles;
