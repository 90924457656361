import {
  BASE_CURRENCY,
  ENDPIONTS,
  getUserInfo,
  httpService,
  PaginatedResult,
} from "@api";
import { AddDeduction, ModifyEmployeePayroll } from "@components";
import { useFetch, useLocalStorage } from "@hooks";
import { Branch, Deduction, SubBranch } from "@models";
import { Action, ComplexHeader, RowModifier, Table } from "@shared";
import { usePayrollStore } from "@stores";
import { DeductionsRef, PayrollPreviewVM } from "@viewModels";
import { BranchSelection } from "@widgets";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const PayrollPreview = () => {
  const [queryStrings] = useSearchParams();
  const navigate = useNavigate();

  const currentUser = getUserInfo();

  const islocalStorageCleared = usePayrollStore((s) => s.isLocalStorageCleared);

  const MySwal = withReactContent(Swal);

  const branchId = queryStrings.get("branchId");

  const [isLoading, setIsLoading] = useState(false);
  const [forMonth, setForMonth] = useState("");
  const [selectedBranchId, setSelectedBranchId] = useState<number>(
    branchId ? +branchId : 0
  );
  const [selectedSubBranchId, setSelectedSubBranchId] = useState(0);

  let fetchData = useFetch<PayrollPreviewVM[]>(
    { endPoint: ENDPIONTS.payrollPreview, queryStrings: queryStrings },
    []
  );

  const fetchSubBranches = useFetch<SubBranch[]>(
    {
      endPoint: ENDPIONTS.subBranches,
      id: selectedBranchId ?? currentUser?.branchId ?? 0,
    },
    []
  );

  const [payrollList, setPayrollList, keyExists, removeKey] = useLocalStorage<
    PayrollPreviewVM[]
  >("payrollList", []);

  const [
    employeeDeductions,
    setEmployeeDeductions,
    employeeDeductionsKeyExists,
    employeeDeductionsRemoveKey,
  ] = useLocalStorage<{ [key: string]: PaginatedResult<Deduction> }>(
    "employeeDeductions",
    {}
  );

  useEffect(() => {
    // if (!fetchData?.data?.isEmpty()) {
    if (
      employeeDeductionsKeyExists() === null ||
      Object.keys(employeeDeductionsKeyExists()!).length === 0
    )
      removeKey();

    if (keyExists() === null || keyExists()?.isEmpty())
      setPayrollList(fetchData?.data);
    // }
  }, [fetchData?.isFetching]);

  useEffect(() => {
    const onStorage = (e: StorageEvent) => {
      if (e.key === "payrollList") onClear();
    };

    window.addEventListener("storage", onStorage);

    return () => {
      window.removeEventListener("storage", onStorage);
    };
  }, []);

  const headers: ComplexHeader[] = [
    { key: "name", title: "Name" },
    { key: "phone", title: "phone" },
    { key: "accountNumber", title: "account Number" },
    {
      key: "allowances",
      title: "Allowances",
      format: "currency",
      currency: () => BASE_CURRENCY,
      total: { format: "currency", currency: BASE_CURRENCY },
    },
    {
      key: "deductions",
      title: "deductions",
      format: "currency",
      currency: () => BASE_CURRENCY,
      total: { format: "currency", currency: BASE_CURRENCY },
    },
    {
      key: "netSalary",
      title: "netSalary",
      format: "currency",
      currency: () => BASE_CURRENCY,
      total: { format: "currency", currency: BASE_CURRENCY },
    },
    {
      key: "netAmount",
      title: "net Amount",
      format: "currency",
      currency: () => BASE_CURRENCY,
      total: { format: "currency", currency: BASE_CURRENCY },
    },
  ];

  const rowModifier: RowModifier = {
    style: (d: PayrollPreviewVM) => {
      if (d.hasChanged === "allowances") return "table-warning";
      if (d.hasChanged === "deductions") return "table-danger";
      return "";
    },
  };

  const actions: Action[] = [
    {
      key: "",
      actionType: "badge",
      click: (d: PayrollPreviewVM, e: any) => {
        const onModify = (
          allowances: number,
          deductions: number,
          deductionsRef: DeductionsRef[]
        ) => {
          const v = payrollList.find((v) => v.id === d.id);
          if (!v) return;
          if (d.allowances !== allowances) {
            // uncomment this in the future if allowances are also modifiable.
            // v.netAmount = (v.netAmount - v.allowances) + allowances;
            // v.allowances = allowances;
            // v.hasChanged = "allowances";
            // const el = document.getElementById(e?.target?.id)
            // el?.closest('tr')?.classList.add('table-warning');
          }
          if (d.deductions !== deductions) {
            v.netAmount = v.netAmount + d.deductions - deductions;
            v.deductions = deductions;
            v.deductionRefs = deductionsRef;
            v.hasChanged = "deductions";
            // const el = document.getElementById(e?.target?.id)
            // el?.closest('tr')?.classList.add('table-danger');
          }
          setPayrollList((prev) => [...payrollList]);
          Swal?.close();
        };

        MySwal.fire({
          showConfirmButton: false,
          allowOutsideClick: false,
          showCloseButton: true,
          width: 1200,
          html: (
            <></>
            // <ModifyEmployeePayroll payrollPreview={d} callback={onModify} />
          ),
        });
      },
      title: "Modify",
      color: "danger",
    },
    {
      key: "",
      actionType: "badge",
      click: (d: PayrollPreviewVM) => {
        onAdvanceClick(d);
      },
      title: "Advance",
      color: "dark",
    },
  ];

  const onAdvanceClick = (d: PayrollPreviewVM) => {
    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 600,
      html: (
        <AddDeduction
          employeeId={d.id}
          isAdvance={true}
          callback={onClear}
          maxAmountForAdvance={d.netAmount}
        />
      ),
    });
  };

  const onGeneratePayroll = async () => {
    const firePopUp = (txt: string) => {
      Swal.fire({
        title: "Oops",
        text: txt,
        icon: "error",
        toast: true,
      });
    };

    setIsLoading(true);

    let dType = {
      payrollPreview: payrollList,
      forMonth: forMonth,
      date: new Date().toISOString(),
    };

    if (!forMonth) {
      firePopUp("Please specify the month.");
      setIsLoading(false);
      return;
    }

    if (payrollList.isEmpty()) {
      firePopUp("Payroll Preview can't be empty.");
      setIsLoading(false);
      return;
    }

    if (payrollList.some((d) => !d.accountNumber)) {
      firePopUp(
        "Can't generate payroll due to the existence of employees without an account number"
      );
      setIsLoading(false);
      return;
    }

    if (payrollList.some((d) => d.netSalary === 0)) {
      firePopUp(
        "Can't generate payroll due to the existence of employees without a salary"
      );
      setIsLoading(false);
      return;
    }

    const res = await httpService(ENDPIONTS.generatePayroll).post(dType);
    if (res.status === 201) {
      onClear();
    }

    setIsLoading(false);
  };

  const onClear = () => {
    removeKey();
    employeeDeductionsRemoveKey();
    // localStorage.removeItem("employeeDeductions");
    window.location.reload();
  };

  const onBranchChange = (id: number) => {
    removeKey();
    employeeDeductionsRemoveKey();

    setSelectedBranchId(id);

    queryStrings.set("branchId", id.toString());

    navigate({ search: `?${queryStrings.toString()}` });
  };

  return (
    <div className="content container-fluid">
      <div className="row align-items-center">
        <div className="col">
          <h3 className="page-title">Payroll Preview</h3>
        </div>
        <div className="col-3">
          <BranchSelection
            isSelectedOption={selectedBranchId}
            endpoint={ENDPIONTS.payrollBranches}
            returnValue={(id: number) => onBranchChange(id)}
          />
        </div>
        <div className="col-3">
          <select
            className="form-control select"
            onChange={(e) => setSelectedSubBranchId(+e.target.value)}
          >
            <option></option>
            {fetchSubBranches?.data?.map((p, i: any) => {
              return (
                <option key={i} value={p.id}>
                  {p.name}
                </option>
              );
            })}
          </select>
        </div>
        <div className="col-auto">
          <input
            value={forMonth}
            type="month"
            className="form-control"
            onChange={(e) => setForMonth(e.target.value)}
          />
        </div>
        <div className="col-auto float-end ms-auto">
          <button
            disabled={isLoading}
            className="btn btn-sm btn-primary m-1"
            onClick={onGeneratePayroll}
          >
            {isLoading ? "Generating..." : "Generate Payroll"}
          </button>
          <button className="btn btn-sm btn-danger m-1" onClick={onClear}>
            Clear
          </button>
        </div>
      </div>
      <div className="col-12 d-flex mt-5">
        <div className="card profile-box flex-fill">
          <div className="card-body">
            <Table
              actions={actions}
              class="table table-striped table-hover mb-0"
              data={
                payrollList?.filterIf(
                  selectedSubBranchId > 0,
                  (p) => p.subBranchId === selectedSubBranchId
                ) ?? []
              }
              headers={headers}
              isFetchingPage={fetchData.isFetching}
              showCounter
              // onPageChange={handlePageChange}
              paginationClass="row mt-3"
              rowModifier={rowModifier}
              showTotals
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayrollPreview;
