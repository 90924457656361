import { ENDPIONTS, PaginatedResult } from "@api";
import { NewRole, RegisterUser } from "@components";
import { useFetch } from "@hooks";
import { Role } from "@models";
import { Action, ComplexHeader, Table } from "@shared";
import { useEffect, useState } from "react";
import { FiPlus } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const ListRoles = () => {
  const MySwal = withReactContent(Swal);
  const navigate = useNavigate();

  const fetchRoles = useFetch<PaginatedResult<Role>>(
    { endPoint: ENDPIONTS.roles },
    new PaginatedResult<Role>()
  );

  useEffect(() => {}, [fetchRoles?.isFetching]);

  const onRoleClickHandler = () => {
    navigate("/roles/new");
  };

  const headers: ComplexHeader[] = [
    { key: "name", title: "Role Name" },
    { key: "description", title: "Description" },
    { key: "user?.userName", title: "By" },
    { key: "createdAt", title: "@", format: "date" },
  ];

  const actions: Action[] = [
    {
      key: "",
      actionType: "badge",
      click: (role: Role) => {
        navigate("/roles/new", { state: role });
      },
      title: "Edit",
      color: "dark",
    },
  ];

  return (
    <div className="content container-fluid">
      <div className="row align-items-center">
        <div className="col">
          <h3 className="page-title">Roles List</h3>
        </div>
        <div className="col-auto float-end ms-auto">
          <button
            className="btn btn-sm btn-primary rounded-circle m-1"
            onClick={onRoleClickHandler}
          >
            <FiPlus />
          </button>
        </div>
      </div>

      <div className="container mt-3">
        <Table
          actions={actions}
          class="table table-striped table-hover mb-0"
          data={fetchRoles?.data ?? []}
          headers={headers}
          isFetchingPage={fetchRoles.isFetching}
          showCounter
          hidePagination
        />
      </div>
    </div>
  );
};

export default ListRoles;
