import { ENDPIONTS } from "@api";
import { useFetch } from "@hooks";
import { Termination } from "@models";
import { Action, ComplexHeader, Table } from "@shared";
import { useEffect } from "react";

interface Props {
  employeeId?: number;
}
const EmployeeTerminations = ({ employeeId }: Props) => {
  const fetchData = useFetch<Termination[]>(
    {
      endPoint: ENDPIONTS.employeeTerminations,
      id: employeeId,
    },
    []
  );

  useEffect(() => {}, [fetchData?.isFetching]);

  const headers: ComplexHeader[] = [
    { key: "reason", title: "reason" },
    { key: "remarks", title: "remarks" },
    { key: "createdBy", title: "createdBy" },
    { key: "createdAt", title: "createdAt",format:"date" },
  ];

  const actions: Action[] = [
    {
      key: "",
      actionType: "badge",
      click: (l: Termination) => window.open(l.path),
      title: "View",
      color: "dark",
      disable: (v: Termination) => v.path === null,
    },
  ];

  return (
    <div className="col-10 offset-1 d-flex">
    <div className="card profile-box flex-fill">
      <div className="card-body">
        <div className="d-flex justify-content-between mb-1">
          <h3>Termination</h3>
        </div>

        <div className="row" >
          <div
            className="d-flex justify-content-end d-none"
            id="DocumentHeader"
          >
            <small>{`Printed on: ${new Date()}`}</small>
          </div>
          <Table
            actions={actions}
            class="table table-striped table-hover mb-0"
            data={fetchData.data ?? []}
            headers={headers}
            isFetchingPage={fetchData.isFetching}
            showCounter
            // onPageChange={handlePageChange}
            paginationClass="row mt-3"
          />
        </div>
      </div>
    </div>
  </div>
  )
};

export default EmployeeTerminations;
