import { ENDPIONTS, httpService } from "@api";
import { Department } from "@models";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

interface Props {
  selectedDepartment?: Department;
  callback?: () => void;
}

const AddDepartment: React.FC<Props> = ({ selectedDepartment, callback }) => {
  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data: any, e: any) => {
    setIsLoading(true);

    const department: Partial<Department> = {
      name: data.name,
    };

    if (selectedDepartment?.id) {
      department.id = selectedDepartment.id;
      const res = await httpService(ENDPIONTS.departments).update(
        selectedDepartment.id,
        department
      );
    } else {
      const res = await httpService(ENDPIONTS.departments).post(department);
      if (res.status === 201) {
        Swal.fire({
          icon: "success",
          text: "New Department has been successfully registered.",
          showConfirmButton: false,
        });
      }
    }

    callback?.();
    setIsLoading(false);
    e.target.reset();
  };

  return (
    <>
      <h4>Add New Department</h4>
      <hr />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Department Name</label>
              <div className="col-12">
                <input
                  defaultValue={selectedDepartment?.name}
                  type="text"
                  className="form-control"
                  placeholder="Enter payroll group name"
                  name="name"
                  ref={register({ required: true })}
                />
              </div>
            </div>
          </div>
        </div>

        <input
          type="submit"
          name="time"
          className="btn btn-primary"
          disabled={isLoading}
          value={
            isLoading
              ? "Please wait..."
              : selectedDepartment?.id
              ? "Update"
              : "Register"
          }
        />
      </form>
    </>
  );
};

export default AddDepartment;
