import { BASE_CURRENCY, ENDPIONTS, PaginatedResult, PagingOptions } from "@api";
import { AddDeduction, DeductionDetails, PayDeduction } from "@components";
import { useFetch } from "@hooks";
import { Deduction } from "@models";
import { Action, ComplexHeader, RowModifier, Table } from "@shared";
import { useEmployeeStore } from "@stores";
import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

interface Props {
  employeeId?: number;
  showInPopUp?: boolean;
  hideInputsInTable?: boolean;
  callback?: (value: {}, deductions: Deduction[]) => void;
  options?: PagingOptions;
  showActionButtons?: boolean;
  employeeDeductions?: {
    [key: string]: PaginatedResult<Deduction>;
  };
}

const ListDeductions: React.FC<Props> = ({
  employeeId,
  showInPopUp,
  callback,
  options,
  employeeDeductions,
  hideInputsInTable = true,
  showActionButtons = true,
}) => {
  const calculateSalary = useEmployeeStore((s) => s.calculateSalary);
  const isSalaryDeductible = useEmployeeStore((s) => s.isSalaryDeductible);

  const MySwal = withReactContent(Swal);

  const [reload, setReload] = useState<boolean | undefined>(undefined);

  const [deductions, setDeductions] = useState<PaginatedResult<Deduction>>(
    new PaginatedResult<Deduction>()
  );
  const values = useRef({});

  let fetchData = useFetch<PaginatedResult<Deduction>>(
    {
      endPoint: ENDPIONTS.employeeDeductions,
      id: employeeId,
      reload: reload,
      queryStrings: options,
    },
    new PaginatedResult<Deduction>()
  );

  useEffect(() => {
    if (!hideInputsInTable) {
      // while accessing deductions from payrolpreview screen
      if (!fetchData.data.items.isEmpty()) {
        deductions.items = fetchData.data.items.map((d) => ({
          ...d,
          cloneMonthlyInstallment: d.monthlyInstallment,
        }));

        let isInLocalStorage = employeeId?.toString()! in employeeDeductions!;
        if (isInLocalStorage) {
          setDeductions({ ...employeeDeductions![employeeId?.toString()!] });
          for (let x of employeeDeductions![employeeId?.toString()!].items) {
            values.current = {
              ...values.current,
              [x.id.toString()]: x.cloneMonthlyInstallment,
            };
          }
        } else {
          setDeductions((prev) => ({ ...prev, items: deductions.items }));

          for (let x of deductions.items) {
            values.current = {
              ...values.current,
              [x.id.toString()]: x.monthlyInstallment,
            };
          }
        }
      }
    } else {
      // accessing deductions normally
      // console.log('I am here boy', fetchData.data);
      setDeductions((prev) => ({ ...fetchData.data }));
    }
  }, [employeeId, hideInputsInTable, fetchData?.isFetching]);

  const handleInputChange = (e: any, deductions: Deduction[]) => {
    const id = e.target.id;
    const name: number = +e.target.name?.split("-")[1];

    values.current = { ...values.current, [name]: +e.target.value };

    const deductionRow = deductions.find((d) => d.id === name)!;
    // if (
    //   +e.target.value < 0 ||
    //   deductionRow.monthlyInstallment < +e.target.value
    // ) {
    //   const input = document.getElementById(id) as HTMLInputElement;
    //   input.style.backgroundColor = "#E5C7CA";
    //   return;
    // }
    deductionRow.cloneMonthlyInstallment = +e.target.value;

    callback?.(values.current, deductions);
  };

  const headers: ComplexHeader[] = [
    { key: "id", title: "ID#", hideCol: hideInputsInTable },
    { key: "deductionType?.name", title: "Deduction Type" },
    {
      key: "amount",
      title: "amount",
      format: "currency",
      currency: () => BASE_CURRENCY,
      total: { format: "currency", currency: BASE_CURRENCY },
    },
    { key: "numberOfMonths", title: "No. of Months" },
    {
      key: "monthlyInstallment",
      title: "Installment",
      format: "currency",
      currency: () => BASE_CURRENCY,
      total: { format: "currency", currency: BASE_CURRENCY },
    },
    { key: "numberOfMonthsLeft", title: "Left Months" },
    {
      key: "ballance",
      title: "ballance",
      format: "currency",
      currency: () => BASE_CURRENCY,
      total: { format: "currency", currency: BASE_CURRENCY },
    },
    { key: "createdAt", title: "@", format: "date" },
    {
      key: "id",
      title: "Modify",
      hideCol: hideInputsInTable,
      input: {
        type: "number",
        value: (val: Deduction) => val?.cloneMonthlyInstallment?.toString(),
        callback: (e, val: Deduction[]) => handleInputChange(e, val),
        disabled: (val: Deduction) => val?.deductionType?.name === "Advance",
      },
    },
  ];

  const rowModifier: RowModifier = {
    style: (d: Deduction) => {
      if (d.ballance === 0) return "table-success";
      if (d.ballance < 0) return "table-danger";
      return "";
    },
  };

  const actions: Action[] = !showActionButtons
    ? []
    : [
        {
          key: "",
          actionType: "badge",
          click: (d: Deduction) => {
            MySwal.fire({
              showConfirmButton: false,
              allowOutsideClick: false,
              showCloseButton: true,
              width: 800,
              html: <DeductionDetails deductionId={d.id} />,
            });
          },
          title: "Details",
          color: "info",
        },
        {
          key: "",
          actionType: "badge",
          click: (d: Deduction) => {
            MySwal.fire({
              showConfirmButton: false,
              allowOutsideClick: false,
              showCloseButton: true,
              width: 600,
              html: <PayDeduction deductionId={d.id} callback={finalize} />,
            });
          },
          title: "Clear",
          color: "danger",
          disable: (d: Deduction) => d.ballance <= 0,
        },
      ];

  const onAddClick = (e: any, a?: Deduction) => {
    if (!isSalaryDeductible) {
      Swal.fire({
        icon: "warning",
        showConfirmButton: false,
        text: "The employee's expected salary is bellow the accepted percentage.",
      });
      return;
    }

    if (!employeeId) return;
    e !== null && e.preventDefault();

    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 600,
      html: (
        <AddDeduction
          employeeId={employeeId}
          deduction={a}
          callback={finalize}
        />
      ),
    });
  };

  const finalize = () => {
    calculateSalary(employeeId!);

    window.location.reload();
  };

  const tableRenderer = () => {
    return (
      <Table
        class="table table-hover mb-0"
        data={deductions ?? []}
        headers={headers}
        isFetchingPage={fetchData.isFetching}
        showCounter
        actions={actions}
        // onPageChange={handlePageChange}
        paginationClass="row mt-3"
        showTotals
        rowModifier={rowModifier}
      />
    );
  };

  return (
    <>
      {showInPopUp ? (
        tableRenderer()
      ) : (
        <div className="col-10 offset-1 d-flex">
          <div className="card profile-box flex-fill">
            <div className="card-body">
              <h3 className="card-title">
                Deductions
                <a
                  href="!#"
                  onClick={(e) => onAddClick(e)}
                  className="edit-icon"
                  data-bs-toggle="modal"
                  data-bs-target="#personal_info_modal"
                >
                  <i className="fa fa-plus" />
                </a>
              </h3>
              {tableRenderer()}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ListDeductions;
