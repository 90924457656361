import { ENDPIONTS, httpService } from '@api';
import { OverTimeType } from '@models';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';

interface Props {
    callback?: () => void
    overtimeType?: OverTimeType
}

const AddOverTimeType: React.FC<Props> = ({ callback, overtimeType }) => {
    const { register, handleSubmit, errors } = useForm();

    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = async (data: any, e: any) => {
        setIsLoading(true);

        let oType: Partial<OverTimeType> = {
            name: data.name,
            rate: data.rate
        }

        if (overtimeType?.id) {
            oType.id = overtimeType.id;
            await httpService(ENDPIONTS.overTimeTypes).update(overtimeType.id, oType);
        } else {
            await httpService(ENDPIONTS.overTimeTypes).post(oType);
        }

        callback?.();
        setIsLoading(false);
        e.target.reset();
    }

    return (
        <>
            <h4>{overtimeType?.id ? "Edit" : "Add"} OverTime Type</h4>
            <hr />
            <form onSubmit={handleSubmit(onSubmit)} >
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label>Overtime Type</label>
                            <div className="col-12">
                                <input type="text" defaultValue={overtimeType?.name ?? ''} className="form-control" name='name' ref={register({ required: true })} />
                            </div>
                            <span className="text-danger">
                                {errors.name && <span>This field is required</span>}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label>Rate</label>
                            <div className="col-12">
                                <input type="number" defaultValue={overtimeType?.rate ?? ''} className="form-control" name='rate' ref={register({ required: true })} />
                            </div>
                            <span className="text-danger">
                                {errors.rate && <span>This field is required</span>}
                            </span>
                        </div>
                    </div>
                </div>
                <input type="submit" name="time" className="btn btn-primary" disabled={isLoading} value={isLoading ? "Please wait..." : "Register"} />
            </form>
        </>
    )
}

export default AddOverTimeType